import { useQuery } from '@apollo/client';
import { PHOTO_PAGE, PHOTOS_META } from 'queries/graphql';
import { Loading, Error, NotFound } from 'components/status/Status';
import PageHeading from 'components/heading/PageHeading';
import PhotoGallery from 'components/gallery/PhotoGallery';
import Head from 'components/_base/head/Head';

function PhotoPage(props) {
  // console.log('Page - PhotoPage.js - props: ', props);

  const { slug } = props;
  const photoPage = useQuery(PHOTO_PAGE);
  const photosMeta = useQuery(PHOTOS_META);

  // console.log(
  //   'Page - PhotoPage.js - useQuery - photoPage - data: ',
  //   photoPage.data,
  //   'Page - PhotoPage.js - useQuery - photosMeta - data: ',
  //   photosMeta.data
  // );

  if (photoPage.loading) {
    return <Loading />;
  }
  if (photoPage.error) {
    return <Error />;
  }
  if (photoPage.data.photopage.data === null) {
    return <NotFound type="Photos" />;
  }

  const { name, seo, pageHeading } = photoPage.data.photopage.data.attributes;

  return (
    <>
      {seo && <Head props={seo} slug={slug} />}
      {pageHeading && pageHeading.isShowHeadingPage && (
        <PageHeading props={pageHeading} name={name} />
      )}
      {photosMeta.data && <PhotoGallery props={photosMeta.data.photos.meta} />}
    </>
  );
}

export default PhotoPage;
