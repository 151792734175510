import { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  Dialog,
  DialogContent,
  Typography,
  CardMedia,
  Fade,
  IconButton,
  useTheme,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Loading } from 'components/status/Status';

function VideoModal(props) {
  // console.log('Component - VideoModal.js props: ', props);

  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);

  if (!props.props) return;

  const { videoId, videoTitle } = props.props;

  return (
    <Container>
      <Dialog fullScreen open={props.open} onClose={props.onClose}>
        <AppBar
          sx={{
            position: 'relative',
            backgroundColor: theme.palette.custom.lightBlue.dark,
          }}
        >
          <Toolbar>
            <Typography
              component="h3"
              variant="h6"
              sx={{
                ml: 2,
                flex: 1,
                color: theme.palette.custom.primary.main,
              }}
            >
              {videoTitle}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.onClose}
              aria-label="close"
              sx={{
                color: theme.palette.custom.primary.main,
                '& .MuiSvgIcon-root': {
                  fontSize: 36,
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '100%',
              height: '100%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 1,
            }}
          >
            <Fade in={isLoading}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <Loading />
              </Box>
            </Fade>
            {videoId && (
              <Fade in={!isLoading}>
                <CardMedia
                  component="iframe"
                  src={`https://www.youtube.com/embed/${videoId}?rel=0`}
                  onLoad={() => setIsLoading(false)}
                  sx={{
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    width: '100%',
                    height: '100%',
                    border: 'none',
                  }}
                />
              </Fade>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default VideoModal;
