import { useContext } from 'react';
import { useApolloClient, gql } from '@apollo/client';
import { Box, Typography, useTheme } from '@mui/material';
import { PageContext } from 'pages/Page';

const CONTACT_CONNECTION = gql`
  query GetSitewide {
    sitewide {
      data {
        attributes {
          contactConnection {
            __typename
            id
            emailAddress
            phoneNumber
          }
        }
      }
    }
  }
`;

function ContactConnection(props) {
  // console.log('Component - ContactConnection.js - props: ', props);

  const pageType = useContext(PageContext);
  const client = useApolloClient();
  const {
    sitewide: {
      data: {
        attributes: { contactConnection },
      },
    },
  } = client.readQuery({
    query: CONTACT_CONNECTION,
  });
  // console.log('Component - ContactConnection.js - readQuery - data: ', data);

  const { phoneNumber, emailAddress } = contactConnection;
  const theme = useTheme();
  const styles = {
    typography: {
      mb: 1.5,
      color:
        pageType === 'Page'
          ? theme.palette.common.black
          : theme.palette.common.white,
      a: {
        textDecoration:
          props.parentComponent === 'FullscreenNavigation'
            ? 'underline'
            : 'none',
        color:
          props.parentComponent === 'FullscreenNavigation'
            ? theme.palette.custom.primary.main
            : 'inherit',
      },
      'a:hover': {
        textDecoration: 'none',
      },
    },
  };

  return (
    <Box sx={{ mb: 5 }}>
      {phoneNumber && (
        <Typography sx={{ ...styles.typography }}>
          PHONE: <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </Typography>
      )}
      {emailAddress && (
        <Typography sx={{ ...styles.typography }}>
          EMAIL: <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
        </Typography>
      )}
    </Box>
  );
}

export default ContactConnection;
