import { useState, createContext } from 'react';
import { useQuery } from '@apollo/client';
import { useTheme, GlobalStyles } from '@mui/material';
import { HOME_PAGE } from 'queries/graphql';
import { Loading, Error, NotFound } from 'components/status/Status';
import PageBackgroundImage from 'components/image/PageBackgroundImage';
import ImageCarousel from 'components/carousel/ImageCarousel';
import TestimonialCarousel from 'components/carousel/TestimonialCarousel';
import SimpleHero from 'components/hero/SimpleHero';
import SimpleText from 'components/text/SimpleText';
import SimpleTestimonial from 'components/testimonial/SimpleTestimonial';
import RandomSimpleTestimonial from 'components/testimonial/RandomSimpleTestimonial';
import TextFieldsWithImage from 'components/text/TextFieldsWithImage';
import FullwidthImage from 'components/image/FullwidthImage';
import Head from 'components/_base/head/Head';

export const PageContext = createContext(null);

function HomePage() {
  const { loading, error, data } = useQuery(HOME_PAGE);

  // console.log('Page - Homepage.js - useQuery - data: ', data);

  const [pageType] = useState('HomePage');
  const theme = useTheme();
  const homePageStyles = (
    <GlobalStyles
      styles={{
        body: { backgroundColor: theme.palette.custom.common.black },
      }}
    />
  );

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  if (data.homepage.data === null) {
    return <NotFound type="Homepage" />;
  }

  const { attributes: homepage } = data.homepage.data;

  return (
    <PageContext.Provider value={pageType}>
      {homePageStyles}
      {homepage.seo && <Head props={homepage.seo} slug={homepage.slug} />}
      {homepage.pageBackgroundImage && (
        <PageBackgroundImage props={homepage.pageBackgroundImage} />
      )}
      {homepage.components &&
        homepage.components.map((component) => {
          switch (component.__typename) {
            case 'ComponentHomepageRandomSimpleTestimonial':
              return (
                component.randomSimpleTestimonial
                  .isShowRandomSimpleTestimonial && (
                  <RandomSimpleTestimonial
                    key={component.__typename + '_' + component.id}
                    props={component}
                  />
                )
              );
            case 'ComponentHomepageFullwidthImage':
              return (
                <FullwidthImage
                  key={component.__typename + '_' + component.id}
                  props={component}
                />
              );

            case 'ComponentHomepageImageCarousel':
              return (
                <ImageCarousel
                  key={component.__typename + '_' + component.id}
                  props={component}
                />
              );
            case 'ComponentHomepageTestimonialCarousel':
              return (
                <TestimonialCarousel
                  key={component.__typename + '_' + component.id}
                  props={component}
                />
              );
            case 'ComponentHomepageSimpleHero':
              return (
                <SimpleHero
                  key={component.__typename + '_' + component.id}
                  props={component}
                />
              );
            case 'ComponentHomepageSimpleText':
              return (
                <SimpleText
                  key={component.__typename + '_' + component.id}
                  props={component}
                />
              );
            case 'ComponentHomepageSimpleTestimonial':
              return (
                <SimpleTestimonial
                  key={component.__typename + '_' + component.id}
                  props={component}
                />
              );
            case 'ComponentHomepageTextFieldsWithImage':
              return (
                <TextFieldsWithImage
                  key={component.__typename + '_' + component.id}
                  props={component}
                />
              );
            default:
              return null;
          }
        })}
    </PageContext.Provider>
  );
}

export default HomePage;
