import { useQuery } from '@apollo/client';
import { TESTIMONIAL_PAGE, TESTIMONIALS_META } from 'queries/graphql';
import { Loading, Error, NotFound } from 'components/status/Status';
import PageHeading from 'components/heading/PageHeading';
import TestimonialsWithPhoto from 'components/testimonial/TestimonialsWithPhoto';
import Head from 'components/_base/head/Head';

function TestimonialPage(props) {
  // console.log('Page - TestimonialPage.js - props: ', props);

  const { slug } = props;
  const testimonialPage = useQuery(TESTIMONIAL_PAGE);
  const testimonialsMeta = useQuery(TESTIMONIALS_META);

  // console.log(
  //   'Page - TestimonialPage.js - useQuery - testimonialPage - data: ',
  //   testimonialPage.data,
  //   'Page - TestimonialPage.js - useQuery - testimonialsMeta - data: ',
  //   testimonialsMeta.data
  // );

  if (testimonialPage.loading) {
    return <Loading />;
  }
  if (testimonialPage.error) {
    return <Error />;
  }
  if (testimonialPage.data.testimonialpage.data === null) {
    return <NotFound type="Testimonials" />;
  }

  const { name, seo, pageHeading } =
    testimonialPage.data.testimonialpage.data.attributes;

  return (
    <>
      {seo && <Head props={seo} slug={slug} />}
      {pageHeading && pageHeading.isShowHeadingPage && (
        <PageHeading props={pageHeading} name={name} />
      )}
      {testimonialsMeta.data && (
        <TestimonialsWithPhoto
          props={testimonialsMeta.data.testimonials.meta}
        />
      )}
    </>
  );
}

export default TestimonialPage;
