import { Container, Box, Stack, useTheme, useMediaQuery } from '@mui/material';
import { keyframes } from '@mui/system';

function PageBackgroundImage(props) {
  // console.log('Component - PageBackgroundImage.js - props: ', props);

  const { url: mobileUrl, alternativeText: mobileAlternativeText } =
    props.props.imageViewportGroup.mobile.data.attributes;

  const { url: tabletUrl, alternativeText: tabletAlternativeText } = props.props
    .imageViewportGroup.tablet.data
    ? props.props.imageViewportGroup.tablet.data.attributes
    : props.props.imageViewportGroup.mobile.data.attributes;

  const { url: laptopUrl, alternativeText: laptopAlternativeText } =
    props.props.imageViewportGroup.laptop.data.attributes;

  const theme = useTheme();
  const breakpointUpMobile = useMediaQuery(theme.breakpoints.up('mobile'));
  const breakpointUpTablet = useMediaQuery(theme.breakpoints.up('tablet'));
  const breakpointUpLaptop = useMediaQuery(theme.breakpoints.up('laptop'));

  const imageSrc = () => {
    if (breakpointUpLaptop) return laptopUrl;
    if (breakpointUpTablet) return tabletUrl;
    if (breakpointUpMobile) return mobileUrl;
  };

  const imageAlt = () => {
    if (breakpointUpLaptop) return laptopAlternativeText;
    if (breakpointUpTablet) return tabletAlternativeText;
    if (breakpointUpMobile) return mobileAlternativeText;
  };

  if (props.props.isEnabled !== true) return;

  const imageAnimation = keyframes`
    from {
      transform: scale(1.25) rotate(0deg);
    }
    to {
      transform: scale(1.2) rotate(0deg);
    }
  `;

  const imageAnimationStyles = {
    animationName: `${imageAnimation}`,
    animationDuration: '10s',
    animationDelay: '1s',
    animationIterationCount: '1',
    animationTimingFunction: 'ease',
    animationDirection: 'normal',
    animationFillMode: 'forwards',
    transform: 'scale(1.25)',
    transformOrigin: 'top center',
  };

  return (
    <Container
      maxWidth="none"
      disableGutters
      sx={{
        position: { xs: 'fixed', lg: 'static' },
        top: '0',
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          width: '100%',
          overflow: 'hidden',

          '&::after': {
            content: '" "',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '10%',
            background:
              'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
          },
        }}
      >
        <Box
          component="img"
          src={imageSrc()}
          alt={imageAlt()}
          sx={{
            objectFit: { xs: 'cover', lg: 'contain' },
            objectPosition: { xs: 'center top' },
            height: '100vh',
            [theme.breakpoints.up('lg')]: {
              ...imageAnimationStyles,
              height: '100%',
            },
          }}
        />
      </Stack>
    </Container>
  );
}

export default PageBackgroundImage;
