import { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Grid,
  useTheme,
  useMediaQuery,
  Skeleton,
  IconButton,
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import VideoModal from 'components/gallery/VideoModal';
import ExternalButton from 'components/button/ExternalButton';

function VideoGallery(props) {
  // console.log('Component - VideoGallery.js - props: ', props);

  const videos = props.props;
  const theme = useTheme();
  const breakpointUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const [open, setOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const handleOpen = (videoId, videoTitle) => {
    setCurrentVideo(() => ({
      videoId,
      videoTitle,
    }));
    setOpen(true);
  };
  const handleClose = () => {
    setCurrentVideo(null);
    setOpen(false);
  };

  return (
    <Container disableGutters>
      <Box sx={{ py: { xs: 1, md: 5 } }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {videos.map((video, index) => (
            <Grid key={video.id} item xs={12} md={6}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <CardContent
                  sx={{
                    overflow: 'hidden',
                    pb: '56.25%',
                    position: 'relative',
                    height: 0,
                  }}
                >
                  <>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width="calc(100% - 32px)"
                      height="100%"
                      sx={{
                        position: 'absolute',
                        top: '16px',
                        left: '16px',
                      }}
                    />
                    <CardMedia
                      component="iframe"
                      src={`https://www.youtube.com/embed/${video.videoId}?rel=0`}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        border: 'none',
                        opacity: 0,
                      }}
                      frameBorder="0"
                      allow="fullscreen"
                      loading="lazy"
                      onLoad={(event) => {
                        event.target.style.opacity = '1';
                        event.target.previousElementSibling.remove();
                      }}
                    />
                  </>
                </CardContent>
                <CardContent
                  sx={{
                    position: 'relative',
                    flex: '1 0 auto',
                    pt: 5,
                  }}
                >
                  <Typography component="div" variant="h5">
                    {video.videoTitle}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    {video.description}
                  </Typography>
                  <IconButton
                    aria-label="fullscreen"
                    onClick={() => handleOpen(video.videoId, video.videoTitle)}
                    sx={{
                      display: breakpointUpLg ? 'flex' : 'none',
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      borderRadius: 0,
                      color: 'text.secondary',
                    }}
                  >
                    <FullscreenIcon />
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="span"
                      sx={{ fontSize: '0.75rem' }}
                    >
                      Full screen
                    </Typography>
                  </IconButton>
                  <ExternalButton props={video.button} type="VideoGallery" />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <VideoModal
        open={open}
        onClose={() => handleClose()}
        props={currentVideo}
      />
    </Container>
  );
}

export default VideoGallery;
