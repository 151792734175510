import { Container, Typography, Link, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import Markdown from 'markdown-to-jsx';

function RichText(props) {
  // console.log('Component - RichText.js - props: ', props);

  const theme = useTheme();

  if (props.props.richText === null) return;

  const { description } = props.props.richText;

  const MarkdownLink = ({ children, ...props }) => (
    <Link {...props}>{children}</Link>
  );

  const Heading = ({ children, ...props }) => (
    <Typography {...props}>{children}</Typography>
  );

  const Paragraph = ({ children, ...props }) => (
    <Typography {...props}>{children}</Typography>
  );

  const StyledMarkdown = styled(Markdown)(({ theme }) => ({}));

  return (
    <Container
      maxWidth="xl"
      sx={{
        mb: { xs: 2, md: 4 },
        py: { xs: 2, md: 3 },
      }}
    >
      <StyledMarkdown
        options={{
          forceBlock: true,
          overrides: {
            p: {
              component: Paragraph,
              props: {
                variant: 'body1',
                sx: {
                  mb: { xs: 2, md: 3 },
                },
              },
            },
            a: {
              component: MarkdownLink,
              props: {
                target: '_blank',
              },
            },
            h2: {
              component: Heading,
              props: {
                component: 'h2',
                variant: 'h3',
                sx: {
                  color: theme.palette.custom.primary.dark,
                  fontWeight: 700,
                  my: 4,
                },
              },
            },
            h3: {
              component: Heading,
              props: {
                component: 'h3',
                variant: 'h6',
                sx: {
                  color: theme.palette.custom.primary.dark,
                  fontWeight: 600,
                  letterSpacing: 4,
                  mb: 1,
                },
              },
            },
          },
        }}
        sx={{
          mt: { xs: 2, md: 5 },
          mb: { xs: 2, md: 15 },
          fontFamily: theme.typography.primary,
        }}
      >
        {description}
      </StyledMarkdown>
    </Container>
  );
}

export default RichText;
