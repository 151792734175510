// import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  useTheme,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { deepmerge } from '@mui/utils';
import { getDesignTokens, getThemedComponents } from 'theme/Theme';
import Homepage from 'pages/Homepage';
import Page from 'pages/Page';
import PhotoPage from 'pages/PhotoPage';
import VideoPage from 'pages/VideoPage';
import TestimonialPage from 'pages/TestimonialPage';
import ArticlePage from 'pages/ArticlePage';
import Category from 'pages/Category';
import PageNotFound from 'pages/PageNotFound';
import Header from 'components/_base/header/Header';
import Footer from 'components/_base/footer/Footer';
import ScrollToTop from 'components/scroll-to-top/ScrollToTop';

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_ENDPOINT}/graphql`,
  fetchOptions: {
    mode: 'no-cors',
  },
  cache: new InMemoryCache(),
});

function App() {
  let theme = createTheme(
    deepmerge(getDesignTokens(useTheme()), getThemedComponents(useTheme()))
  );
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        <ApolloProvider client={client}>
          <Header />
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route path="/:slug" element={<Page />} />
            <Route exact path="/photos" element={<PhotoPage slug="photos" />} />
            <Route exact path="/videos" element={<VideoPage slug="videos" />} />
            <Route
              exact
              path="/testimonials"
              element={<TestimonialPage slug="testimonials" />}
            />
            <Route
              exact
              path="/articles"
              element={<ArticlePage slug="articles" />}
            />
            <Route path="/category/:slug" element={<Category />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </ApolloProvider>
      </Router>
    </ThemeProvider>
  );
}
export default App;
