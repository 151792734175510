import { alpha } from '@mui/material';
import { grey } from '@mui/material/colors';

const palette = {
  custom: {
    primary: {
      light: '#F2D383',
      main: '#A58F59',
      dark: '#8C7A4C',
    },
    grey: {
      light: '#DAD1CA',
      main: '#EBE1DA',
      dark: '#6B6764',
    },
    darkBlue: {
      light: '#324269',
      main: '#182033',
      dark: '#0D111B',
    },
    lightBlue: {
      light: '#C8D2F7',
      main: '#616678',
      dark: '#2C2E36',
    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
  },
};

export const getDesignTokens = (theme) => ({
  palette: {
    custom: {
      primary: {
        light: palette.custom.primary.light,
        main: palette.custom.primary.main,
        dark: palette.custom.primary.dark,
      },
      grey: {
        light: palette.custom.grey.light,
        main: palette.custom.grey.main,
        dark: palette.custom.grey.dark,
      },
      darkBlue: {
        light: palette.custom.darkBlue.light,
        main: palette.custom.darkBlue.main,
        dark: palette.custom.darkBlue.dark,
      },
      lightBlue: {
        light: palette.custom.lightBlue.light,
        main: palette.custom.lightBlue.main,
        dark: palette.custom.lightBlue.dark,
      },
      common: {
        white: palette.custom.common.white,
        black: palette.custom.common.black,
      },
    },
  },
  typography: {
    fontFamily: [
      'Raleway',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    body1: {
      fontFamily: 'Raleway, Arial, sans-serif',
    },
    primary: {
      fontFamily: 'Raleway, Arial, sans-serif',
    },
    secondary: {
      fontFamily: "'Playfair Display', serif",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1200,
      desktop: 1600,
    },
  },
});

export const getThemedComponents = (theme) => ({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: palette.custom.common.white,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          '&.MuiAppBar-fullscreenNavigation': {
            zIndex: '1201',
            boxShadow: 'none',
            backgroundColor: 'transparent',

            [theme.breakpoints.up('lg')]: {
              backgroundColor: palette.custom.lightBlue.dark,
            },
            '&.drawer--open': {
              backgroundColor: 'transparent',
              pointerEvents: 'none',

              '& .MuiToolbar-fullscreenNavigation': {
                visibility: 'hidden',
              },
              '& .hamburger-react': {
                pointerEvents: 'auto',
              },
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.custom.primary.main,
          textDecorationColor: palette.custom.primary.main,
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
      variants: [
        {
          props: { variant: 'dark' },
          style: {
            color: palette.custom.primary.dark,
            fontWeight: 'bold',
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            backgroundColor: palette.custom.primary.main,
            color: palette.custom.common.black,
            '&:hover': {
              backgroundColor: palette.custom.primary.light,
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: palette.custom.primary.main,
            borderColor: palette.custom.primary.main,
            textTransform: 'uppercase',
            fontWeight: 600,
            '&:hover': {
              color: palette.custom.primary.light,
              borderColor: palette.custom.primary.main,
              backgroundColor: alpha(palette.custom.primary.dark, 0.5),
            },
          },
        },
      ],
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          wordWrap: 'break-word',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: 'auto',
          margin: 10,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-filled': {
            color: theme.palette.common.black,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderWidth: 0,
          borderStyle: 'solid',
          borderColor: palette.custom.primary.main,
          backgroundColor: palette.custom.common.white,

          '&:hover': {
            backgroundColor: grey[200],
            borderColor: palette.custom.primary.main,
          },
          '&.Mui-focused': {
            backgroundColor: grey[50],
            borderColor: palette.custom.primary.dark,
          },
          '::after': {
            borderColor: palette.custom.primary.main,
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '&.MuiContainer--relatively-positioned': {
            position: 'relative',
          },
          '&.MuiContainer--with-margin': {
            marginBottom: 16,
            [theme.breakpoints.up('md')]: {
              marginBottom: 32,
            },
          },
          '&.MuiContainer-maxWidthXl:not(.MuiContainer-disableGutters)': {
            [theme.breakpoints.up('md')]: {
              paddingLeft: 128,
              paddingRight: 128,
            },
          },
          '&.MuiContainer--dark-transparent-mode': {
            backgroundColor: alpha(palette.custom.darkBlue.dark, 0.78),
          },
          '&.MuiContainer--light-mode': {
            backgroundColor: palette.custom.grey.light,
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          padding: 5,
        },
      },
    },
  },
});
