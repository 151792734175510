import { useApolloClient, gql } from '@apollo/client';

const WEBSITE_TITLE = gql`
  query GetSitewide {
    sitewide {
      data {
        attributes {
          header {
            __typename
            id
            siteTitle
          }
        }
      }
    }
  }
`;

function WebsiteTitle() {
  const client = useApolloClient();
  const {
    sitewide: {
      data: {
        attributes: { header },
      },
    },
  } = client.readQuery({
    query: WEBSITE_TITLE,
  });

  // console.log('Component - WebsiteTitle.js - readQuery - header:', header);

  const { siteTitle } = header;

  return <> {siteTitle} </>;
}

export default WebsiteTitle;
