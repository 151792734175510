import { Box, Typography, useTheme } from '@mui/material';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function TestimonialSlide(props) {
  // console.log('Component - TestimonialSlide.js - props: ', props);

  const theme = useTheme();

  const detailStyles = {
    textAlign: 'center',
    emphasis: {
      fontSize: 20,
      fontWeight: 700,
    },
    supplementary: {
      fontSize: 16,
    },
  };

  if (props.props === null) return;

  const { description, name, position, location } = props.props;

  return (
    <Box
      component="div"
      sx={{
        mx: { xs: 2, md: 'auto' },
        my: { xs: 2, md: 15 },
        maxWidth: { xs: '100%', md: '100%' },
        color: theme.palette.custom.primary.main,
        fontFamily: theme.typography.primary,
      }}
    >
      <Box
        component="div"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          mx: { xs: 2, md: 8 },
          mb: { xs: 2, md: 4 },

          '&::after': {
            content: '" "',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '20%',
            background:
              'linear-gradient(0deg, rgba(0,0,0,.75) 0%, rgba(0,0,0,0) 100%)',
          },
        }}
      >
        <Box
          component="div"
          sx={{
            p: { xs: 2, md: 3 },
            overflowY: 'scroll',
            height: { xs: '350px' },
            // '::-webkit-scrollbar': {
            //   width: '20px',
            // },
            // '::-webkit-scrollbar-thumb': {
            //   backgroundColor: alpha(theme.palette.custom.primary.dark, 0.35),
            // },
            // '::-webkit-scrollbar-thumb:hover': {
            //   backgroundColor: theme.palette.custom.primary.main,
            // },
            // '::-webkit-scrollbar-thumb:active': {
            //   backgroundColor: theme.palette.custom.primary.light,
            // },
            // '::-webkit-scrollbar-track': {
            //   backgroundColor: alpha(theme.palette.common.black, 0.25),
            // },
          }}
        >
          <Typography
            sx={{
              position: 'relative',
              pt: 10,
              mb: { xs: 5, md: 5 },
              fontSize: { xs: 18, lg: 25 },
              fontWeight: 'inherit',
              fontStyle: 'italic',
              textAlign: 'left',

              '&::before': {
                content: '" “"',
                float: 'left',
                ml: '-30px',
                height: 0,
                fontSize: { xs: 150, lg: 250 },
                fontFamily: theme.typography.secondary,
                lineHeight: 0,
              },
            }}
          >
            {description}
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          ...detailStyles,
          ...detailStyles.emphasis,
        }}
      >
        {name}
      </Typography>
      {position && (
        <Typography
          sx={{
            ...detailStyles,
            ...detailStyles.supplementary,
          }}
        >
          {position}
        </Typography>
      )}
      {location && (
        <Typography
          sx={{
            ...detailStyles,
            ...detailStyles.supplementary,
          }}
        >
          {location}
        </Typography>
      )}
    </Box>
  );
}

export default TestimonialSlide;
