import { useContext } from 'react';
import {
  Container,
  Box,
  Typography,
  Link,
  useTheme,
  styled,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import { ExpandMore, ArrowForwardIosSharp } from '@mui/icons-material';
import Markdown from 'markdown-to-jsx';
import { PageContext } from 'pages/Homepage';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .025)',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.custom.primary.main,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

function SimpleAccordion(props) {
  // console.log('Component - SimpleAccordion.js - props: ', props);

  const pageType = useContext(PageContext);
  const theme = useTheme();

  if (props.props === null) return;

  const { isOpenAllSummaries, isOpenFirstSummary, simpleAccordionSummaries } =
    props.props;

  const MarkdownLink = ({ children, ...props }) => (
    <Link {...props} variant="dark">
      {children}
    </Link>
  );

  return (
    <Container
      maxWidth="none"
      className={`MuiContainer--relatively-positioned MuiContainer--with-margin ${
        pageType === 'HomePage' && 'MuiContainer--dark-transparent-mode'
      } `}
    >
      <Container maxWidth="xl" sx={{ my: { xs: 2, md: 3 } }}>
        <Box>
          {simpleAccordionSummaries &&
            simpleAccordionSummaries.map((summary, index) => (
              <MuiAccordion
                key={summary.id}
                defaultExpanded={
                  isOpenAllSummaries
                    ? true
                    : isOpenFirstSummary && index === 0
                    ? true
                    : false
                }
                square
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ color: theme.palette.custom.primary.main }}
                >
                  <Typography component="h3" variant="h5">
                    {summary.heading}
                  </Typography>
                </AccordionSummary>
                <MuiAccordionDetails>
                  <Markdown
                    options={{
                      forceBlock: true,
                      overrides: {
                        a: {
                          component: MarkdownLink,
                          props: {
                            className: 'MuiLink--markdown',
                            target: '_blank',
                          },
                        },
                      },
                    }}
                  >
                    {summary.description}
                  </Markdown>
                </MuiAccordionDetails>
              </MuiAccordion>
            ))}
        </Box>
      </Container>
    </Container>
  );
}

export default SimpleAccordion;
