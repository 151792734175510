import { useRef, useState, cloneElement, forwardRef } from 'react';
import { useQuery } from '@apollo/client';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Drawer,
  AppBar,
  Typography,
  Toolbar,
  Link,
  List,
  Slide,
  Grid,
  Stack,
  MenuItem,
  useScrollTrigger,
  Zoom,
  Box,
  Fab,
  alpha,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Hamburger from 'hamburger-react';
import { NAVIGATION } from 'queries/graphql.js';
import { Loading, Error, NotFound } from 'components/status/Status';
import ContactConnection from 'components/contact/ContactConnection';
import WebsiteTitle from 'components/website/WebsiteTitle';

function HeaderScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const routerLocation = useLocation();
  const theme = useTheme();

  return cloneElement(children, {
    sx: trigger
      ? {
          transitionDuration: '500ms',
          transitionProperty: 'padding-top, padding-bottom, background-color',
          transitionTimingFunction: 'ease-in-out',
          backgroundColor:
            routerLocation.pathname === '/'
              ? theme.palette.custom.lightBlue.dark
              : theme.palette.custom.common.white,
        }
      : {
          pt: 1.5,
          pb: 1.5,
        },
    elevation: trigger ? 5 : 0,
  });
}

function ScrollTop(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 200,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 10 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

const drawerWidth = '100%';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function FullscreenNavigation(props) {
  const [isOpen, setIsOpen] = useState(false);
  const appBar = useRef(null);
  const appBarTitle = useRef(null);
  const theme = useTheme();
  const breakpointUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const { loading, error, data } = useQuery(NAVIGATION);
  // console.log('Component - FullscreenNavigation.js - useQuery - data: ', data);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  if (data.navigation.data === null) {
    return <NotFound type="Fullscreen Navigation" />;
  }

  const menuItems = data.navigation.data.attributes.links;

  const styleDrawer = {
    Paper: {
      pseudo: {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        transitionProperty: 'transform, opacity',
        backgroundColor: 'black',
      },
      pseudoBefore: {
        zIndex: '-2',
        transitionDelay: '0',
      },
      pseudoAfter: {
        zIndex: '-1',
        transform: 'skewY(0deg) translateY(0%)',
        transitionDelay: '.45s',
      },
      pseudoNotOpen: {
        opacity: '0',
        transform: 'skewY(-20deg) translateY(-70%)',
        transitionTimingFunction: 'ease-in',
        transitionDuration: '0',
      },
      pseudoOpen: {
        transform: 'skewY(0deg) translateY(0%)',
        transitionTimingFunction: 'ease-out',
        transitionDuration: '0.5s',
      },
    },
  };

  return (
    <>
      <HeaderScroll {...props}>
        <AppBar
          ref={appBar}
          component="nav"
          variant="custom"
          color="custom"
          position="sticky"
          open={isOpen}
          className={`MuiAppBar-fullscreenNavigation ${
            isOpen ? 'drawer--open' : null
          }`}
        >
          <Toolbar>
            <Typography
              ref={appBarTitle}
              component="div"
              variant="h6"
              noWrap
              className="MuiToolbar-fullscreenNavigation"
              sx={{
                flexGrow: 1,
              }}
            >
              <Link
                to="/"
                component={RouterLink}
                color="inherit"
                underline="none"
                variant="h1"
                sx={{
                  fontSize: { xs: 24 },
                  fontFamily: theme.typography.secondary.fontFamily,
                  color: theme.palette.custom.primary.main,
                  p: 1,
                  '&:hover': {
                    color: theme.palette.custom.primary.light,
                  },
                }}
              >
                <WebsiteTitle />
              </Link>
            </Typography>
            <Hamburger
              toggled={isOpen}
              toggle={setIsOpen}
              size={34}
              direction="left"
              rounded={true}
              label="Show menu"
              color={theme.palette.custom.primary.main}
            />
          </Toolbar>
        </AppBar>
      </HeaderScroll>
      <Drawer
        id="back-to-top-anchor"
        SlideProps={{
          easing: {
            enter: 'cubic-bezier(.05,.89,.13,.98)',
            exit: 'linear',
          },
        }}
        TransitionComponent={Transition}
        variant="persistent"
        anchor="top"
        open={isOpen}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            backgroundColor: 'transparent',
            width: drawerWidth,
            height: '100%',
            visibility: 'visible',
            overflow: 'visible',
            ...(!isOpen && {
              '&::before': {
                ...styleDrawer.Paper.pseudo,
                ...styleDrawer.Paper.pseudoBefore,
                ...styleDrawer.Paper.pseudoNotOpen,
              },
              '&::after': {
                ...styleDrawer.Paper.pseudo,
                ...styleDrawer.Paper.pseudoAfter,
                ...styleDrawer.Paper.pseudoNotOpen,
              },
            }),
            ...(isOpen && {
              '&::before': {
                ...styleDrawer.Paper.pseudo,
                ...styleDrawer.Paper.pseudoBefore,
                ...styleDrawer.Paper.pseudoOpen,
                opacity: '.45',
              },
              '&::after': {
                ...styleDrawer.Paper.pseudo,
                ...styleDrawer.Paper.pseudoAfter,
                ...styleDrawer.Paper.pseudoOpen,
                opacity: '.85',
              },
            }),
          },
        }}
      >
        <Grid
          container
          spacing={breakpointUpLg ? 3 : 0}
          columns={{ xs: 4, lg: 12 }}
          sx={{
            margin: 0,
            width: '100%',
            height: 'inherit',
          }}
        >
          {breakpointUpLg && (
            <Grid
              item
              xs={12}
              lg={4}
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                overflow: 'hidden',
              }}
            >
              <Stack
                direction="column"
                sx={{
                  transitionProperty: 'transform',
                  transitionDuration: '.35s',
                  transitionTimingFunction: 'ease-out',
                  transform: 'translateY(100%)',

                  ...(isOpen && {
                    transform: 'translateY(0)',
                    transitionDelay: '.75s',
                  }),
                }}
              >
                <ContactConnection parentComponent="FullscreenNavigation" />
              </Stack>
            </Grid>
          )}
          <Grid item xs={12} lg={8}>
            <List
              sx={{
                width: '100%',

                [theme.breakpoints.up('lg')]: {
                  height: '100%',
                  display: 'flex',
                  rowGap: '3rem',
                  flexWrap: 'wrap',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  alignContent: 'center',
                },
              }}
            >
              {menuItems.map((menuItem, index) => {
                return (
                  <MenuItem
                    key={menuItem.id + index}
                    sx={{
                      position: 'relative',
                      p: 0,
                      transitionProperty: 'transform',
                      transitionDuration: '2.5s',
                      transitionTimingFunction: 'ease-out',
                      flex: '0 1 30%',
                      width: 'auto',

                      ...(!isOpen && {
                        transform: 'translateY(100%)',

                        '&::before': {
                          content: '" "',
                          position: 'absolute',
                          top: '-1px',
                          left: 0,
                          height: '2px',
                          width: '0',
                        },

                        '&::after': {
                          content: '" "',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          height: '1px',
                          width: '0',
                          backgroundColor: alpha(
                            theme.palette.custom.primary.dark,
                            0
                          ),
                        },
                      }),
                      ...(isOpen && {
                        transform: 'translateY(0)',
                        transitionDelay: `${(index + 1) * 0.125}s`,

                        '&::before': {
                          content: '" "',
                          position: 'absolute',
                          top: '-1px',
                          left: 0,
                          height: '2px',
                          width: '30px',
                          backgroundColor: theme.palette.custom.primary.dark,
                          transitionProperty: 'width',
                          transitionTimingFunction: 'ease-out',
                          transitionDuration: '.35s',
                          transitionDelay: `${(index + 0) * 0.175}s`,
                        },
                        '&::after': {
                          content: '" "',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          height: '1px',
                          width: '100%',
                          backgroundColor: alpha(
                            theme.palette.custom.primary.dark,
                            0.25
                          ),
                          transitionProperty: 'width, background-color',
                          transitionTimingFunction: 'ease-out',
                          transitionDuration: '.35s',
                          transitionDelay: `${(index + 1) * 0.15}s`,
                        },
                      }),
                    }}
                  >
                    <Link
                      to={menuItem.url}
                      component={RouterLink}
                      color="inherit"
                      underline="none"
                      aria-current="page"
                      onClick={() => setIsOpen(false)}
                      sx={{
                        position: 'relative',
                        flexGrow: 1,
                        textTransform: 'uppercase',
                        p: 2,
                        color: theme.palette.custom.primary.main,
                        fontSize: 22,
                        fontWeight: 600,

                        ...(!isOpen && {
                          opacity: 0,
                          transform: 'translateY(-10px)',
                        }),
                        ...(isOpen && {
                          opacity: 1,
                          transform: 'translateY(0)',
                          transitionProperty: 'transform, opacity',
                          transitionDuration: '.35s',
                          transitionTimingFunction: 'ease-out',
                          transitionDelay: `${(index + 1) * 0.25}s`,
                        }),

                        [theme.breakpoints.up('lg')]: {
                          fontSize: 24,
                          fontWeight: 'normal',
                        },

                        '&::before': {
                          content: '" "',
                          position: 'absolute',
                          top: '-1px',
                          left: 0,
                          height: '2px',
                          width: 0,
                          backgroundColor: theme.palette.custom.primary.light,
                          transitionTimingFunction: 'ease-out',
                          transitionDuration: '.35s',
                          transitionDelay: 0,
                        },
                        '&:hover': {
                          color: theme.palette.custom.primary.light,
                          '&::before': {
                            width: '15%',
                          },
                        },
                      }}
                    >
                      {menuItem.label}
                    </Link>
                  </MenuItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Drawer>
      <ScrollTop {...props}>
        <Fab
          size="small"
          variant="extended"
          aria-label="scroll back to top"
          sx={{
            border: '2px solid',
            backgroundColor: theme.palette.custom.primary.dark,
            borderColor: 'common.white',
            color: 'common.white',
            '&:hover': {
              borderColor: theme.palette.custom.primary.dark,
              color: theme.palette.custom.primary.dark,
              backgroundColor: 'common.white',
            },
          }}
        >
          <KeyboardArrowUpIcon
            sx={{
              fontSize: 40,
            }}
          />
        </Fab>
      </ScrollTop>
    </>
  );
}

export default FullscreenNavigation;
