import { useRef, useEffect, useContext } from 'react';
import {
  Container,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { PageContext } from 'pages/Homepage';
gsap.registerPlugin(ScrollTrigger);

function SimpleTestimonial(props) {
  // console.log('Component - SimpleTestimonial.js - props: ', props);

  const pageType = useContext(PageContext);
  const theme = useTheme();
  const breakpointUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const descriptionRef = useRef(null);

  const detailStyles = {
    textAlign: 'right',
    emphasis: {
      fontSize: 20,
      fontWeight: 700,
    },
    supplementary: {
      fontSize: 16,
    },
  };

  useEffect(() => {
    if (breakpointUpLg && descriptionRef.current) {
      var tl = gsap.timeline();
      tl.fromTo(
        descriptionRef.current,
        {
          '--pseudoOpacity': '0',
          '--pseudoX': '-150%',
          y: '0%',
        },
        {
          '--pseudoOpacity': '1',
          '--pseudoX': '-50%',
          y: '0%',
          scrollTrigger: {
            trigger: descriptionRef.current,
            start: 'top 80%',
            end: 'top top',
            toggleActions: 'play complete reverse reset',
          },
        }
      );
    }
  }, [breakpointUpLg, descriptionRef]);

  if (props.props.simpleTestimonial === null) return;

  const { description, name, position, location } =
    props.props.simpleTestimonial;

  return (
    <Container
      maxWidth="none"
      className={`MuiContainer--relatively-positioned MuiContainer--with-margin simple-testimonial ${
        pageType === 'HomePage' && 'MuiContainer--dark-transparent-mode'
      } `}
    >
      <Container
        maxWidth="xl"
        sx={{
          py: { xs: 2, md: 3 },
        }}
      >
        <Box
          component="div"
          sx={{
            mx: { xs: 2, md: 'auto' },
            my: { xs: 2, md: 10 },
            maxWidth: { xs: '100%', md: '100%' },
            color: theme.palette.custom.primary.main,
            fontFamily: theme.typography.primary,
          }}
        >
          <Typography
            ref={descriptionRef}
            sx={{
              position: 'relative',
              pt: 3,
              mt: { xs: 8, md: 0 },
              mb: { xs: 3, md: 2 },
              fontSize: { xs: 16, lg: 22 },
              fontWeight: { xs: 'inherit' },
              fontStyle: 'italic',
              textAlign: { xs: 'justify', lg: 'center' },
              '&::before': {
                content: '" “"',
                position: 'absolute',
                left: '50%',
                bottom: '100%',
                fontFamily: theme.typography.secondary,
                lineHeight: 0,
                fontSize: 160,
                transform: 'translateX(-80%)',

                [theme.breakpoints.up('lg')]: {
                  left: 0,
                  fontSize: 250,
                  opacity: 'var(--pseudoOpacity)',
                  transform: 'translateX(var(--pseudoX))',
                  transition: 'transform .5s ease-out, opacity .75s ease-out',
                },
              },
            }}
          >
            {description}
          </Typography>
          <Typography
            sx={{
              ...detailStyles,
              ...detailStyles.emphasis,
            }}
          >
            {name}
          </Typography>
          {position && (
            <Typography
              sx={{
                ...detailStyles,
                ...detailStyles.supplementary,
              }}
            >
              {position}
            </Typography>
          )}
          {location && (
            <Typography
              sx={{
                ...detailStyles,
                ...detailStyles.supplementary,
              }}
            >
              {location}
            </Typography>
          )}
        </Box>
      </Container>
    </Container>
  );
}

export default SimpleTestimonial;
