import { useQuery } from '@apollo/client';
import { ARTICLE_PAGE, ARTICLES_META } from 'queries/graphql';
import { Loading, Error, NotFound } from 'components/status/Status';
import PageHeading from 'components/heading/PageHeading';
import Articles from 'components/article/Articles';
import Head from 'components/_base/head/Head';

function ArticlePage(props) {
  // console.log('Page - ArticlePage.js - props: ', props);

  const { slug } = props;
  const articlePage = useQuery(ARTICLE_PAGE);
  const articlesMeta = useQuery(ARTICLES_META);

  if (articlePage.loading) {
    return <Loading />;
  }
  if (articlePage.error) {
    return <Error />;
  }
  if (articlePage.data.articlepage.data === null) {
    return <NotFound type="Articles" />;
  }

  const { name, seo, pageHeading } =
    articlePage.data.articlepage.data.attributes;

  return (
    <>
      {seo && <Head props={seo} slug={slug} />}
      {pageHeading && pageHeading.isShowHeadingPage && (
        <PageHeading props={pageHeading} name={name} />
      )}
      {articlesMeta.data && (
        <Articles props={articlesMeta.data.articles.meta} />
      )}
    </>
  );
}

export default ArticlePage;
