import { useRef, useEffect, useContext } from 'react';
import {
  Container,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  alpha,
} from '@mui/material';
import gsap from 'gsap';
import { PageContext } from 'pages/Homepage';

function SimpleHero(props) {
  // console.log('Component - SimpleHero.js - props: ', props);

  const pageType = useContext(PageContext);
  const { heading, headingEmphasis, subHeading } = props.props.simpleHero;
  const theme = useTheme();
  const breakpointUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const headingInnerRef = useRef(null);
  const headingEmphasisInnerRef = useRef(null);
  const headingEmphasisInnerOverlayRef = useRef(null);
  const subHeadingInnerRef = useRef(null);

  const headingStyle = {
    overflow: { lg: 'hidden' },
    lineHeight: { xs: 1 },
  };
  const headingInnerStyle = {
    display: { lg: 'inline-block' },
    position: { lg: 'relative' },
    transform: { lg: 'translate(0, 100%)' },
    px: { xs: 1, lg: 0 },
  };

  useEffect(() => {
    if (breakpointUpLg) {
      const tl = gsap.timeline();

      tl.fromTo(
        headingInnerRef.current,
        { y: '100%' },
        {
          y: '0%',
          duration: 0.5,
          ease: 'expo.out',
          delay: 0.5,
        }
      )
        .fromTo(
          headingEmphasisInnerRef.current,
          { y: '100%' },
          {
            y: '0%',
            duration: 1,
            ease: 'expo.out',
            delay: 0.15,
          },
          '<'
        )
        .fromTo(
          subHeadingInnerRef.current,
          { y: '100%' },
          { y: '0%', duration: 0.5, ease: 'expo.out', delay: 0.5 },
          '<'
        )
        .fromTo(
          headingEmphasisInnerOverlayRef.current,
          { backgroundPosition: 'calc(0% - 100px)', opacity: 1 },
          {
            backgroundPosition: 'calc(100% + 100px)',
            opacity: 0.35,
            duration: 2,
            ease: 'power1.out',
            delay: 0.5,
          }
        );
    }
  }, [breakpointUpLg]);

  return (
    <Container
      maxWidth="none"
      className="MuiContainer--relatively-positioned MuiContainer--with-margin simple-hero"
      sx={{
        [theme.breakpoints.up('lg')]: {
          ...(pageType === 'HomePage' && {
            backgroundColor: alpha(theme.palette.custom.darkBlue.dark, 0.35),
          }),
        },
      }}
    >
      <Container
        maxWidth="xl"
        disableGutters
        sx={{
          position: { xs: 'relative', md: 'static' },
          height: '100vh',
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'inline-block',
            position: { xs: 'relative' },
            top: { xs: '50%', md: '22%' },
            left: { xs: '50%', md: 'initial' },
            mx: { xs: 0, md: 4 },
            my: { xs: 0, md: 20 },
            textAlign: 'right',
            color: theme.palette.custom.primary.main,
            transform: {
              xs: 'translate(-50%, -50%)',
              md: 'translateY(-50%)',
            },
          }}
        >
          <Typography
            sx={{
              position: { lg: 'absolute' },
              top: { lg: 0 },
              right: { lg: 0 },
              fontFamily: theme.typography.primary,
              fontSize: { xs: 40, lg: 50 },
              fontWeight: { xs: 400, lg: 200 },
              ...headingStyle,
            }}
          >
            <Typography
              ref={headingInnerRef}
              component="span"
              variant="inherit"
              sx={{
                ...headingInnerStyle,
                lineHeight: { xs: 1.15, lg: 1.2 },
                backgroundColor: {
                  xs: alpha(theme.palette.common.black, 0.4),
                  lg: 'transparent',
                },
              }}
            >
              {heading}
            </Typography>
          </Typography>
          <Typography
            sx={{
              fontFamily: theme.typography.secondary,
              fontSize: { xs: 120, sm: 150, lg: 250 },
              fontWeight: 600,
              mr: { lg: -2 },
              ...headingStyle,
              backgroundColor: {
                xs: alpha(theme.palette.common.black, 0.4),
                lg: 'transparent',
              },
            }}
          >
            <Typography
              ref={headingEmphasisInnerOverlayRef}
              component="span"
              variant="inherit"
              sx={{
                display: { xs: 'none', lg: 'inline-block' },
                position: 'absolute',
                zIndex: 1,
                background:
                  'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)',
                backgroundClip: 'text',
                textFillColor: 'transparent',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100px 100%',
                backgroundPositionX: 'calc(0% - 100px)',
              }}
            >
              {headingEmphasis}
            </Typography>
            <Typography
              ref={headingEmphasisInnerRef}
              component="span"
              variant="inherit"
              sx={{
                ...headingInnerStyle,
                px: { xs: 1, lg: 0 },
              }}
            >
              {headingEmphasis}
            </Typography>
          </Typography>
          <Typography
            sx={{
              fontFamily: theme.typography.primary,
              fontSize: { xs: 40, lg: 50 },
              fontWeight: { xs: 400, lg: 200 },
              ...headingStyle,
            }}
          >
            <Typography
              ref={subHeadingInnerRef}
              component="span"
              variant="inherit"
              sx={{
                ...headingInnerStyle,
                lineHeight: { xs: 1.2, lg: 1 },
                backgroundColor: {
                  xs: alpha(theme.palette.common.black, 0.4),
                  lg: 'transparent',
                },
              }}
            >
              {subHeading}
            </Typography>
          </Typography>
        </Box>
      </Container>
    </Container>
  );
}

export default SimpleHero;
