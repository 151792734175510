import { Stack, Box, Typography, CircularProgress } from '@mui/material';
import { Code as CodeIcon, CodeOff as CodeOffIcon } from '@mui/icons-material';

const style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  fontWeight: '400',
  p: { xs: 4 },
  fontSize: { xs: 12 },
  '& .MuiSvgIcon-root': {
    fontSize: 'inherit',
    margin: 0.35,
  },
};

const Loading = () => {
  return (
    <Stack alignItems="center">
      <CircularProgress sx={{ m: 1 }} color="primary" />
      <Box
        sx={{
          ...style,
          color: 'warning.main',
        }}
      >
        <CodeIcon />
        Data is loading...
        <CodeOffIcon />
      </Box>
    </Stack>
  );
};

const Error = () => {
  return (
    <Box
      sx={{
        ...style,
        color: 'warning.main',
      }}
    >
      <CodeIcon />
      Error loading data!
      <CodeOffIcon />
    </Box>
  );
};

const NotFound = (props) => {
  const { type } = props;
  return (
    <Box
      sx={{
        ...style,
        color: 'warning.main',
      }}
    >
      <CodeIcon />
      {type} Not Found!
      <CodeOffIcon />
    </Box>
  );
};

const NoContent = () => {
  return (
    <Typography
      sx={{
        ...style,
        color: 'primary.main',
      }}
    >
      No content has been added yet. Please check back soon.
    </Typography>
  );
};

export { Loading, Error, NotFound, NoContent };
