import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Container,
  Pagination,
  Stack,
  Typography,
  Grid,
  Card,
  CardActions,
  CardContent,
  Button,
  Divider,
  useTheme,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import Image from 'mui-image';
import { ARTICLES } from 'queries/graphql';
import ArticleCategories from 'components/article/ArticleCategories';
import { Loading, Error, NotFound } from 'components/status/Status';

function Articles(props) {
  // console.log('Component - Articles.js - props: ', props);

  const theme = useTheme();
  const [page, setPage] = useState(props.props.pagination.page);
  const pageCount = props.props.pagination.pageCount;
  const pageSize = props.props.pagination.pageSize;
  const total = props.props.pagination.total;
  const [isArticlesLoading, setIsArticlesLoading] = useState(true);
  const [articles, setArticles] = useState([]);

  const buttonStyles = {
    px: 6,
    textAlign: 'center',
  };
  const buttonProps = {
    size: 'large',
  };

  const [getArticles, { called, loading, error }] = useLazyQuery(ARTICLES, {
    onCompleted: (data) => {
      console.log(
        'Component - useLazyQuery - Articles.js - onCompleted - data: ',
        data
      );
      setArticles(data.articles.data);
      setIsArticlesLoading(false);
    },
    fetchPolicy: 'network-only', // Doesn't check cache before making a network request
  });

  useEffect(() => {
    getArticles({ variables: { page: page, pageSize: pageSize } });
  }, [page, pageSize, getArticles]);

  const handleChange = (value) => {
    setIsArticlesLoading(true);
    setPage(value);
  };

  if (called && loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  if (articles === null) {
    return <NotFound />;
  }

  return (
    <Container
      maxWidth="none"
      disableGutters
      className="MuiContainer--relatively-positioned MuiContainer--with-margin"
    >
      <Container
        maxWidth="xl"
        sx={{
          py: { xs: 2, md: 3 },
        }}
      >
        <Grid container>
          {articles.map((article, index) => {
            return (
              <Grid
                key={article.id}
                item
                xs={12}
                md={6}
                sx={{
                  mb: { xs: 2 },
                }}
              >
                <Card
                  raised
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    m: { xs: 0, md: 2 },
                    p: { lg: 2 },
                    height: '100%',
                  }}
                >
                  {article.attributes.image.data && (
                    <Image
                      key={article.attributes.image.id}
                      src={article.attributes.image.data.attributes.url}
                      alt={
                        article.attributes.image.data.attributes.alternativeText
                      }
                      fit="contain"
                      showLoading
                      duration={500}
                      width="100%"
                      height="auto"
                      style={{
                        height: 'auto',
                      }}
                      sx={{
                        maxHeight: '300px',
                      }}
                    />
                  )}
                  <CardContent
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Typography
                      gutterBottom
                      component="h3"
                      variant="h5"
                      align="center"
                      sx={{
                        fontFamily: theme.typography.secondary.fontFamily,
                        fontStyle: 'italic',
                        fontWeight: 700,
                      }}
                    >
                      {article.attributes.title}
                    </Typography>
                    <Divider
                      variant="middle"
                      sx={{
                        maxWidth: 30,
                        mx: 'auto',
                        borderBottomWidth: 2,
                        marginBottom: 1.3,
                      }}
                    />
                    <Typography
                      gutterBottom
                      align="center"
                      sx={{ fontWeight: 500, color: theme.palette.grey[600] }}
                    >
                      {format(
                        parseISO(article.attributes.publishedDate),
                        'MMMM dd, yyyy'
                      )}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {article.attributes.description}
                    </Typography>
                    <Divider
                      variant="middle"
                      sx={{
                        maxWidth: 30,
                        mx: 'auto',
                        my: 1.3,
                        borderBottomWidth: 2,
                      }}
                    />
                    {article.attributes.categories.data.length > 0 && (
                      <>
                        <Typography
                          gutterBottom
                          align="center"
                          sx={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: theme.palette.grey[600],
                          }}
                        >
                          Categories
                        </Typography>
                        <ArticleCategories
                          categories={article.attributes.categories}
                          type="simple"
                        />
                      </>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button
                      href={article.attributes.link}
                      rel="noreferrer noopener"
                      target="_blank"
                      disableElevation
                      color="inherit"
                      {...buttonProps}
                      sx={{
                        ...buttonStyles,
                        backgroundColor: 'transparent',
                        px: 1,
                        letterSpacing: 5,
                        color: theme.palette.grey[500],
                        fontSize: 14,
                        fontWeight: 600,
                        '&:hover': {
                          backgroundColor: theme.palette.grey[200],
                        },
                      }}
                    >
                      Read More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        {pageCount > 1 && (
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ py: { xs: 1, md: 3 } }}
          >
            <Pagination
              count={pageCount}
              page={page}
              onChange={handleChange}
              size="large"
              showFirstButton
              showLastButton
              siblingCount={1}
              boundaryCount={1}
              disabled={isArticlesLoading}
            />
            <Typography sx={{ color: 'white', fontSize: '75%' }}>
              {(page - 1) * pageSize + 1} - {page * pageSize} of {total}
            </Typography>
          </Stack>
        )}
      </Container>
    </Container>
  );
}

export default Articles;
