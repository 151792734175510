import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Container, Button, Box, Typography, useTheme } from '@mui/material';
import { CATEGORY } from 'queries/graphql';
import { Loading, Error, NotFound } from 'components/status/Status';
import Head from 'components/_base/head/Head';
import PageHeading from 'components/heading/PageHeading';

function Category() {
  const { slug } = useParams();
  const { loading, error, data } = useQuery(CATEGORY, {
    variables: {
      slug: slug,
    },
  });

  // console.log('Page - Category.js - useQuery - data: ', data);

  const theme = useTheme();
  const buttonStyle = {
    px: 6,
    textAlign: 'center',
  };
  const buttonProps = {
    size: 'large',
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  if (data.findSlug.data === null) {
    return <NotFound type="Category" />;
  }
  const category = data.findSlug.data.attributes;
  const seo = {
    title: `Category - ${category.name}`,
    slug: `category/${category.slug}`,
  };

  return (
    <>
      {seo && <Head props={seo} slug={slug} />}
      <Container
        maxWidth="none"
        disableGutters
        className="MuiContainer--relatively-positioned MuiContainer--with-margin"
      >
        <Container
          maxWidth="xl"
          sx={{
            py: { xs: 2, md: 3 },
          }}
        >
          {category.name && (
            <PageHeading props={category.name} name={category.name} />
          )}
          {category.articles.data.map((article) => (
            <Box key={article.id}>
              <Typography
                component="h3"
                variant="h5"
                sx={{
                  mb: { xs: 1, md: 2 },
                }}
              >
                {article.attributes.title}
              </Typography>

              {article.attributes.description && (
                <Typography
                  component="p"
                  variant="body1"
                  sx={{
                    mb: { xs: 1, md: 2 },
                  }}
                >
                  {article.attributes.description.substring(0, 200)}...
                </Typography>
              )}
              <Button
                href={article.attributes.link}
                rel="noreferrer noopener"
                target="_blank"
                disableElevation
                color="inherit"
                {...buttonProps}
                sx={{
                  ...buttonStyle,
                  backgroundColor: 'transparent',
                  px: 1,
                  letterSpacing: 5,
                  color: theme.palette.grey[500],
                  fontSize: 14,
                  fontWeight: 600,
                  '&:hover': {
                    backgroundColor: theme.palette.grey[200],
                  },
                }}
              >
                Read More
              </Button>
            </Box>
          ))}
        </Container>
      </Container>
    </>
  );
}

export default Category;
