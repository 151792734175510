import { useRef, useEffect, useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import {
  Container,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { RANDOM_TESTIMONIAL } from 'queries/graphql';
import { Loading, Error, NotFound } from 'components/status/Status';
import { PageContext } from 'pages/Homepage';
gsap.registerPlugin(ScrollTrigger);

function RandomSimpleTestimonial() {
  const randomTestimonial = useQuery(RANDOM_TESTIMONIAL, {
    fetchPolicy: 'no-cache',
  });

  // console.log(
  //   'Component - RandomSimpleTestimonial.js - useQuery - data: ',
  //   randomTestimonial.data
  // );

  const pageType = useContext(PageContext);
  const theme = useTheme();
  const breakpointUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const descriptionRef = useRef(null);
  const [descriptionRefVisible, setDescriptionRefVisible] = useState(false);

  const detailStyles = {
    textAlign: 'right',
    emphasis: {
      fontWeight: 700,
      fontSize: 16,
      [theme.breakpoints.up('lg')]: {
        fontSize: 20,
      },
    },
    supplementary: {
      fontSize: 16,
    },
  };

  useEffect(() => {
    if (breakpointUpLg && descriptionRefVisible) {
      const tl = gsap.timeline();

      tl.fromTo(
        descriptionRef.current,
        {
          '--pseudoOpacity': '0',
          '--pseudoX': '-150%',
          y: '0%',
        },
        {
          '--pseudoOpacity': '1',
          '--pseudoX': '-50%',
          y: '0%',
          scrollTrigger: {
            trigger: descriptionRef.current,
            start: 'top 80%',
            end: 'top top',
            toggleActions: 'play complete reverse reset',
          },
        }
      );
    }
  }, [breakpointUpLg, descriptionRefVisible]);

  if (randomTestimonial.loading) {
    return <Loading />;
  }
  if (randomTestimonial.error) {
    return <Error />;
  }
  if (randomTestimonial.data === null) {
    return <NotFound type="Random Simple Testimonial" />;
  }

  const { description, name, position, location } =
    randomTestimonial.data.randomTestimonial;

  return (
    <Container
      maxWidth="none"
      disableGutters
      className={`MuiContainer--relatively-positioned random-simple-testimonial ${
        pageType === 'HomePage' && 'MuiContainer--dark-transparent-mode'
      } `}
    >
      <Container
        maxWidth="xl"
        sx={{
          py: { xs: 2, md: 3 },
        }}
      >
        <Box
          component="div"
          sx={{
            mx: { xs: 2, md: 'auto' },
            my: { xs: 2, md: 10 },
            maxWidth: { xs: '100%', md: '100%' },
            color: theme.palette.custom.primary.main,
            fontFamily: theme.typography.primary,
          }}
        >
          <Typography
            ref={(el) => {
              descriptionRef.current = el;
              setDescriptionRefVisible(el);
            }}
            sx={{
              position: 'relative',
              pt: 3,
              mt: { xs: 8, md: 0 },
              mb: { xs: 3, md: 2 },
              fontSize: { xs: 16, lg: 22 },
              fontWeight: { xs: 'inherit' },
              fontStyle: 'italic',
              textAlign: { xs: 'justify', lg: 'center' },

              '&::before': {
                content: '" “"',
                position: 'absolute',
                left: '50%',
                bottom: '100%',
                fontFamily: theme.typography.secondary,
                lineHeight: 0,
                fontSize: 160,
                transform: 'translateX(-80%)',

                [theme.breakpoints.up('lg')]: {
                  left: 0,
                  fontSize: 250,
                  opacity: 'var(--pseudoOpacity)',
                  transform: 'translateX(var(--pseudoX))',
                  transition: 'transform .5s ease-out, opacity .75s ease-out',
                },
              },
            }}
          >
            {description}
          </Typography>
          <Typography
            sx={{
              ...detailStyles,
              ...detailStyles.emphasis,
            }}
          >
            {name}
          </Typography>
          {position && (
            <Typography
              sx={{
                ...detailStyles,
                ...detailStyles.supplementary,
              }}
            >
              {position}
            </Typography>
          )}
          {location && (
            <Typography
              sx={{
                ...detailStyles,
                ...detailStyles.supplementary,
              }}
            >
              {location}
            </Typography>
          )}
        </Box>
      </Container>
    </Container>
  );
}

export default RandomSimpleTestimonial;
