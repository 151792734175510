import { Container, Button, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ImageSlide from 'components/carousel/ImageSlide';

SwiperCore.use([Autoplay, Pagination, Navigation]);

function ImageCarousel(props) {
  // console.log('Component - ImageCarousel.js - props: ', props);

  const theme = useTheme();
  const StyledSwiper = styled(Swiper)(({ theme }) => ({}));

  const {
    carouselImageSlides,
    carouselProperties: {
      isAutoplay = true,
      isNavigation = true,
      isFullWidth = false,
      direction = 'horizontal',
    },
  } = props.props.imageCarousel;

  const swiperStyles = {
    swiper: {
      border: '5px solid green !important',
      width: '100%',

      boxSizing: 'border-box',
    },
    slide: {
      boxSizing: 'border-box',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    link: {
      display: 'flex',
      justifyContent: 'center',
      p: 0,
      flexGrow: 1,
    },
  };

  const swiperProps = {
    direction: direction,
    pagination: {
      dynamicBullets: true,
      clickable: true,
    },
    navigation: isNavigation,
    loop: true,
    speed: 2000,
    autoplay: isAutoplay
      ? { delay: 2000, disableOnInteraction: false, pauseOnMouseEnter: true }
      : false,
  };

  return (
    <Container maxWidth="none" disableGutters>
      <Container maxWidth={isFullWidth ? 'none' : 'xl'} disableGutters>
        <StyledSwiper
          {...swiperProps}
          modules={[Autoplay, Pagination, Navigation]}
          style={swiperStyles.swiper}
          sx={{
            '& .swiper-button-next, .swiper-button-prev': {
              color: theme.palette.custom.primary.main,
              [direction === 'vertical']: {
                left: '50%',
                transform: 'rotate(90deg)',
                transformOrigin: 'left center',
              },
            },
            '& .swiper-button-next': {
              [direction === 'vertical']: {},
            },
            '& .swiper-button-prev': {
              [direction === 'vertical']: {},
            },
            '& .swiper-pagination-bullet': {
              top: '10px',
            },
            '& .swiper-pagination-bullet-active': {
              background: theme.palette.custom.primary.main,
            },
          }}
        >
          {carouselImageSlides.map((slide) => {
            return (
              <SwiperSlide key={slide.id} style={swiperStyles.slide}>
                {slide.link ? (
                  <Button
                    href={slide.link}
                    rel="noreferrer noopener"
                    target="_blank"
                    sx={{ ...swiperStyles.link }}
                  >
                    <ImageSlide props={slide} />
                  </Button>
                ) : (
                  <span style={swiperStyles.link}>
                    <ImageSlide props={slide} />
                  </span>
                )}
              </SwiperSlide>
            );
          })}
        </StyledSwiper>
      </Container>
    </Container>
  );
}

export default ImageCarousel;
