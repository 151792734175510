import { Container, Box } from '@mui/material';
import ContactAddress from 'components/contact/ContactAddress';
import ContactConnection from 'components/contact/ContactConnection';

function ContactInformation() {
  return (
    <Container
      maxWidth="none"
      className="MuiContainer--relatively-positioned MuiContainer--with-margin"
    >
      <Container maxWidth="xl">
        <Box
          component="div"
          sx={{
            mt: { xs: 2, md: 2 },
            mb: { xs: 2, md: 2 },
          }}
        >
          <ContactAddress />
          <ContactConnection />
        </Box>
      </Container>
    </Container>
  );
}

export default ContactInformation;
