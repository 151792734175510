import { useQuery } from '@apollo/client';
import { VIDEO_PAGE } from 'queries/graphql';
import { Loading, Error, NotFound } from 'components/status/Status';
import PageHeading from 'components/heading/PageHeading';
import VideoGallery from 'components/gallery/VideoGallery';
import Head from 'components/_base/head/Head';

function VideoPage(props) {
  // console.log('Page - VideoPage.js - props: ', props);

  const { slug } = props;
  const videoPage = useQuery(VIDEO_PAGE);

  // console.log(
  //   'Page - VideoPage.js - useQuery - videoPage - data: ',
  //   videoPage.data
  // );

  if (videoPage.loading) {
    return <Loading />;
  }
  if (videoPage.error) {
    return <Error />;
  }
  if (videoPage.data.videopage.data === null) {
    return <NotFound type="Videos" />;
  }

  const { name, videos, seo, pageHeading } =
    videoPage.data.videopage.data.attributes;

  return (
    <>
      {seo && <Head props={seo} slug={slug} />}
      {pageHeading && pageHeading.isShowHeadingPage && (
        <PageHeading props={pageHeading} name={name} />
      )}
      {videos && <VideoGallery props={videos} />}
    </>
  );
}

export default VideoPage;
