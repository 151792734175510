import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Container,
  Box,
  Pagination,
  Stack,
  Typography,
  Paper,
  useTheme,
  styled,
} from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import { PHOTOS } from 'queries/graphql';
import PhotoModal from 'components/gallery/PhotoModal';
import { Loading, Error, NotFound } from 'components/status/Status';

const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.common.black,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

function PhotoGallery(props) {
  // console.log('Component - PhotoGallery.js - props: ', props);

  const theme = useTheme();
  const [page, setPage] = useState(props.props.pagination.page);
  const customPageSize = 30;
  const pageCount = Math.ceil(props.props.pagination.total / customPageSize);
  const pageSize = customPageSize;
  const total = props.props.pagination.total;
  const [isPhotosLoading, setIsPhotosLoading] = useState(true);
  const [photos, setPhotos] = useState([]);

  const [getPhotos, { called, loading, error }] = useLazyQuery(PHOTOS, {
    onCompleted: (data) => {
      // console.log('Component - PhotoGallery.js - onCompleted - data: ', data);
      setPhotos(data.photos.data);
      setIsPhotosLoading(false);
    },
    fetchPolicy: 'network-only', // Doesn't check cache before making a network request
  });

  useEffect(() => {
    getPhotos({
      variables: { page: page, pageSize: pageSize },
    });
  }, [page, pageSize, getPhotos]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [page]);

  const handleChange = (event, value) => {
    setIsPhotosLoading(true);
    setPage(value);
  };

  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const handleOpen = (url, alt, title, description) => {
    setCurrentImage(() => ({
      url,
      alt,
      title,
      description,
    }));

    setOpen(true);
  };
  const handleClose = () => {
    setCurrentImage(null);
    setOpen(false);
  };

  if (called && loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  if (photos === null) {
    return <NotFound />;
  }

  return (
    <Container maxWidth="none" className="MuiContainer--relatively-positioned">
      <Container
        maxWidth="xl"
        sx={{
          py: { xs: 1, md: 5 },
        }}
      >
        <Box>
          <Masonry
            columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
            spacing={0}
            sx={{ m: 0, backgroundColor: theme.palette.custom.lightBlue.dark }}
          >
            {!isPhotosLoading &&
              photos.map(
                (photo, index) =>
                  photo.attributes.image.data && (
                    <Paper
                      key={index + photo.id + photo.attributes.createdAt}
                      onClick={() => {
                        handleOpen(
                          photo.attributes.image.data.attributes.formats.large
                            ? photo.attributes.image.data.attributes.formats
                                .large.url
                            : photo.attributes.image.data.attributes.url,
                          photo.attributes.image.data.attributes
                            .alternativeText,
                          photo.attributes.image.data.attributes.caption,
                          photo.attributes.description
                        );
                      }}
                      square={true}
                      elevation={0}
                      sx={{
                        cursor: 'pointer',
                        transition: 'transform 0.125s ease-out',
                        transformOrigin: 'center bottom',
                        ':nth-of-type(even)': {
                          filter: 'grayscale(100%)',
                        },
                        ':hover': {
                          filter: 'grayscale(0)',
                          transform: 'scale(1.025)',
                          position: 'relative',
                          zIndex: '1',
                        },
                      }}
                    >
                      {photo.attributes.description && (
                        <Label>{photo.attributes.description}</Label>
                      )}
                      <img
                        src={
                          photo.attributes.image.data.attributes.formats.small
                            ? photo.attributes.image.data.attributes.formats
                                .small.url
                            : photo.attributes.image.data.attributes.url
                        }
                        alt={
                          photo.attributes.image.data.attributes.alternativeText
                        }
                        title={photo.attributes.image.data.attributes.caption}
                        loading="lazy"
                        style={{
                          display: 'block',
                          width: '100%',
                        }}
                      />
                    </Paper>
                  )
              )}
          </Masonry>
        </Box>
        <PhotoModal
          open={open}
          onClose={() => handleClose()}
          props={currentImage}
        />
        {pageCount > 1 && (
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              py: { xs: 1, md: 3 },
            }}
          >
            <Pagination
              count={pageCount}
              page={page}
              onChange={handleChange}
              size="large"
              showFirstButton
              showLastButton
              siblingCount={1}
              boundaryCount={1}
              disabled={isPhotosLoading}
            />
            <Typography sx={{ color: 'white', fontSize: '75%' }}>
              {(page - 1) * pageSize + 1} - {page * pageSize} of {total}
            </Typography>
          </Stack>
        )}
      </Container>
    </Container>
  );
}

export default PhotoGallery;
