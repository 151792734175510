import { gql } from '@apollo/client';

const HOME_PAGE = gql`
  query GetHomepage {
    homepage {
      __typename
      data {
        __typename
        id
        attributes {
          __typename
          seo {
            __typename
            id
            title
            description
            blockSearchIndexing
          }
          pageBackgroundImage {
            __typename
            id
            isEnabled
            imageViewportGroup {
              __typename
              id
              mobile {
                __typename
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              tablet {
                __typename
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              laptop {
                __typename
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
            }
          }
          components {
            __typename
            ... on ComponentHomepageFullwidthImage {
              __typename
              id
              fullwidthImage {
                __typename
                isFullWidth
                imageViewportGroup {
                  __typename
                  id
                  mobile {
                    __typename
                    data {
                      attributes {
                        url
                        alternativeText
                      }
                    }
                  }
                  tablet {
                    __typename
                    data {
                      attributes {
                        url
                        alternativeText
                      }
                    }
                  }
                  laptop {
                    __typename
                    data {
                      attributes {
                        url
                        alternativeText
                      }
                    }
                  }
                }
              }
            }
            ... on ComponentHomepageTestimonialCarousel {
              __typename
              id
              testimonialCarousel {
                __typename
                id
                simpleTestimonialSlides {
                  __typename
                  id
                  name
                  position
                  location
                  description
                }
                carouselProperties {
                  __typename
                  id
                  isAutoplay
                  isNavigation
                  isFullWidth
                  direction
                }
              }
            }
            ... on ComponentHomepageImageCarousel {
              __typename
              id
              imageCarousel {
                __typename
                id
                carouselProperties {
                  __typename
                  id
                  isAutoplay
                  isNavigation
                  isFullWidth
                  direction
                }
                carouselImageSlides {
                  __typename
                  id
                  image {
                    __typename
                    data {
                      __typename
                      id
                      attributes {
                        __typename
                        url
                        alternativeText
                      }
                    }
                  }
                  link
                }
              }
            }
            ... on ComponentHomepageRandomSimpleTestimonial {
              __typename
              id
              randomSimpleTestimonial {
                __typename
                id
                isShowRandomSimpleTestimonial
              }
            }
            ... on ComponentHomepageSimpleHero {
              __typename
              id
              simpleHero {
                heading
                headingEmphasis
                subHeading
              }
            }
            ... on ComponentHomepageSimpleText {
              __typename
              id
              simpleText {
                description
              }
            }
            ... on ComponentHomepageSimpleTestimonial {
              __typename
              id
              simpleTestimonial {
                description
                name
                location
              }
            }
            ... on ComponentHomepageTextFieldsWithImage {
              __typename
              id
              textFieldsWithImage {
                heading
                description
                subDescription
                image {
                  data {
                    attributes {
                      url
                      alternativeText
                    }
                  }
                }
                isContentReversed
                isDescriptionAQuote
                isLightMode
                isImageSkew
                imageOverlayOpacity
              }
              button {
                id
                text
                link
                isOutlined
                isExternal
              }
            }
          }
        }
      }
    }
  }
`;

const PAGE = gql`
  query GetPage($slug: String!) {
    findSlug(modelName: "page", slug: $slug, publicationState: "live") {
      __typename
      ... on PageEntityResponse {
        data {
          __typename
          id
          attributes {
            __typename
            name
            slug
            seo {
              __typename
              id
              title
              description
              blockSearchIndexing
            }
            pageHeading {
              __typename
              id
              isShowHeadingPage
              heading
            }
            components {
              __typename
              ... on ComponentPageRichText {
                __typename
                id
                richText {
                  __typename
                  id
                  description
                }
              }
              ... on ComponentPageRandomSimpleTestimonial {
                __typename
                id
                randomSimpleTestimonial {
                  __typename
                  id
                  isShowRandomSimpleTestimonial
                }
              }
              ... on ComponentPageSimpleTestimonial {
                __typename
                id
                simpleTestimonial {
                  __typename
                  description
                  name
                  position
                  location
                }
              }
              ... on ComponentPageFullwidthImage {
                __typename
                id
                fullwidthImage {
                  __typename
                  isFullWidth
                  isFillFullWidth
                  isSetHeight
                  imageViewportGroup {
                    __typename
                    id
                    mobile {
                      __typename
                      data {
                        attributes {
                          url
                          alternativeText
                        }
                      }
                    }
                    tablet {
                      __typename
                      data {
                        attributes {
                          url
                          alternativeText
                        }
                      }
                    }
                    laptop {
                      __typename
                      data {
                        attributes {
                          url
                          alternativeText
                        }
                      }
                    }
                  }
                }
              }
              ... on ComponentPageImageCarousel {
                __typename
                id
                imageCarousel {
                  __typename
                  id
                  carouselProperties {
                    __typename
                    id
                    isAutoplay
                    isNavigation
                    isFullWidth
                    direction
                  }
                  carouselImageSlides {
                    __typename
                    id
                    image {
                      __typename
                      data {
                        __typename
                        id
                        attributes {
                          __typename
                          url
                          alternativeText
                        }
                      }
                    }
                    link
                  }
                }
              }
              ... on ComponentPageContactForm {
                __typename
                id
                isShowForm
              }
              ... on ComponentPageTestimonialWithPhoto {
                __typename
                id
                testimonials {
                  __typename
                  id
                  description
                  name
                  position
                  location
                  photo {
                    __typename
                    data {
                      attributes {
                        url
                        alternativeText
                        formats
                      }
                    }
                  }
                }
              }
              ... on ComponentPageSimpleAccordion {
                __typename
                id
                isOpenAllSummaries
                isOpenFirstSummary
                simpleAccordionSummaries {
                  __typename
                  id
                  heading
                  description
                }
              }
              ... on ComponentPageNestedAccordion {
                __typename
                id
                isOpenAllSummaries
                isOpenFirstSummary
                nestedAccordionSummaries {
                  __typename
                  id
                  heading
                  nestedAccordionDetails {
                    __typename
                    id
                    heading
                    description
                  }
                }
              }
              ... on ComponentPageContactInformation {
                __typename
                id
                isShowContactInformation
              }
              ... on ComponentPageRichTextWithImage {
                __typename
                id
                richTextWithImage {
                  description
                  image {
                    data {
                      attributes {
                        url
                        alternativeText
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const POSTS = gql`
  query GetPosts {
    posts {
      ... on PostEntityResponseCollection {
        data {
          __typename
          id
          attributes {
            __typename
            name
            slug
            content
            excerpt
            featuredImage {
              data {
                __typename
                id
                attributes {
                  url
                  alternativeText
                  caption
                }
              }
            }
            createdAt
            categories {
              __typename
              data {
                __typename
                id
                attributes {
                  __typename
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

const POST = gql`
  query GetPost($slug: String!) {
    findSlug(modelName: "post", slug: $slug, publicationState: "live") {
      ... on PostEntityResponse {
        data {
          __typename
          id
          attributes {
            createdAt
            name
            slug
            content
            video {
              __typename
              id
              videoId
            }
            seo {
              __typename
              id
              title
              description
              blockSearchIndexing
            }
            categories {
              __typename
              data {
                __typename
                id
                attributes {
                  __typename
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CATEGORIES = gql`
  query GetCategories {
    categories {
      ... on CategoryEntityResponseCollection {
        data {
          __typename
          id
          attributes {
            createdAt
            name
            slug
          }
        }
      }
    }
  }
`;

const CATEGORY = gql`
  query GetCategory($slug: String!) {
    findSlug(modelName: "category", slug: $slug, publicationState: "live") {
      ... on CategoryEntityResponse {
        data {
          __typename
          id
          attributes {
            createdAt
            name
            slug
            articles {
              __typename
              data {
                __typename
                id
                attributes {
                  __typename
                  createdAt
                  title
                  link
                  description
                }
              }
            }
          }
        }
      }
    }
  }
`;

const NAVIGATION = gql`
  query GetNavigation {
    navigation {
      ... on NavigationEntityResponse {
        __typename
        data {
          __typename
          id
          attributes {
            __typename
            ... on Navigation {
              __typename
              links {
                __typename
                id
                label
                url
                isShowInFooter
              }
            }
          }
        }
      }
    }
  }
`;

const SITEWIDE = gql`
  query GetSitewide {
    sitewide {
      __typename
      data {
        __typename
        id
        attributes {
          __typename
          header {
            __typename
            id
            siteTitle
          }
          footer {
            __typename
            id
            heading
            copyright
            isShowWebsiteCredit
            isShowNewsletterForm
            isShowFooterNavigation
          }
          contactAddress {
            __typename
            id
            addressName
            addressLine
            addressCity
            addressState
            addressZipCode
            addressMapLink
            addressDescription
          }
          contactConnection {
            __typename
            id
            emailAddress
            phoneNumber
          }
        }
      }
    }
  }
`;

const PHOTO_PAGE = gql`
  query GetPhotoPage {
    photopage {
      __typename
      data {
        attributes {
          __typename
          name
          seo {
            __typename
            title
            description
            blockSearchIndexing
          }
          pageHeading {
            __typename
            heading
            isShowHeadingPage
          }
        }
      }
    }
  }
`;

const PHOTOS_META = gql`
  query GetPhotosMeta {
    photos {
      __typename
      meta {
        __typename
        pagination {
          __typename
          page
          pageSize
          pageCount
          total
        }
      }
    }
  }
`;

const PHOTOS = gql`
  query GetPhotos($page: Int!, $pageSize: Int!) {
    photos(
      pagination: { page: $page, pageSize: $pageSize }
      sort: "publishedAt:desc"
    ) {
      __typename
      data {
        __typename
        id
        attributes {
          __typename
          createdAt
          description
          image {
            data {
              id
              attributes {
                url
                alternativeText
                caption
                formats
              }
            }
          }
        }
      }
    }
  }
`;

const VIDEO_PAGE = gql`
  query GetPhotoPage {
    videopage {
      __typename
      data {
        attributes {
          __typename
          name
          videos {
            __typename
            id
            videoId
            videoTitle
            description
            button {
              __typename
              id
              text
              link
              isOutlined
            }
          }
          pageHeading {
            __typename
            heading
            isShowHeadingPage
          }
          seo {
            __typename
            title
            description
            blockSearchIndexing
          }
        }
      }
    }
  }
`;

const TESTIMONIAL_PAGE = gql`
  query GetTestimonialPage {
    testimonialpage {
      __typename
      data {
        attributes {
          __typename
          name
          seo {
            __typename
            title
            description
            blockSearchIndexing
          }
          pageHeading {
            __typename
            heading
            isShowHeadingPage
          }
        }
      }
    }
  }
`;

const TESTIMONIALS_META = gql`
  query GetTestimonialsMeta {
    testimonials {
      __typename
      meta {
        __typename
        pagination {
          __typename
          page
          pageSize
          pageCount
          total
        }
      }
    }
  }
`;

const TESTIMONIALS = gql`
  query GetTestimonials($page: Int!, $pageSize: Int!) {
    testimonials(
      pagination: { page: $page, pageSize: $pageSize }
      sort: "publishedAt:desc"
    ) {
      __typename
      data {
        __typename
        id
        attributes {
          __typename
          description
          name
          position
          location
          photo {
            __typename
            data {
              __typename
              id
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;

const ARTICLE_PAGE = gql`
  query GetArticlePage {
    articlepage {
      __typename
      data {
        attributes {
          __typename
          name
          seo {
            __typename
            title
            description
            blockSearchIndexing
          }
          pageHeading {
            __typename
            heading
            isShowHeadingPage
          }
        }
      }
    }
  }
`;

const ARTICLES_META = gql`
  query GetArticlesMeta {
    articles {
      __typename
      meta {
        __typename
        pagination {
          __typename
          page
          pageSize
          pageCount
          total
        }
      }
    }
  }
`;

const ARTICLES = gql`
  query GetArticles($page: Int!, $pageSize: Int!) {
    articles(
      pagination: { page: $page, pageSize: $pageSize }
      sort: "publishedDate:desc"
    ) {
      __typename
      data {
        __typename
        id
        attributes {
          __typename
          publishedAt
          title
          link
          description
          image {
            __typename
            data {
              __typename
              id
              attributes {
                url
                alternativeText
              }
            }
          }
          publishedDate
          categories {
            __typename
            data {
              __typename
              id
              attributes {
                __typename
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`;

const RANDOM_TESTIMONIAL = gql`
  query GetRandomTestimonial {
    randomTestimonial {
      __typename
      name
      position
      location
      description
    }
  }
`;

export {
  HOME_PAGE,
  PHOTO_PAGE,
  PHOTOS_META,
  PHOTOS,
  VIDEO_PAGE,
  TESTIMONIAL_PAGE,
  TESTIMONIALS_META,
  TESTIMONIALS,
  RANDOM_TESTIMONIAL,
  ARTICLE_PAGE,
  ARTICLES_META,
  ARTICLES,
  PAGE,
  POSTS,
  POST,
  CATEGORIES,
  CATEGORY,
  NAVIGATION,
  SITEWIDE,
};
