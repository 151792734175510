import Image from 'mui-image';

function ImageSlide(props) {
  // console.log('Component - ImageSlide.js - props: ', props);

  const { url, alternativeText } = props.props.image.data.attributes;

  return (
    <Image
      src={url}
      alt={alternativeText}
      showLoading
      duration={500}
      width="100%"
      fit="cover"
      sx={{
        height: { xs: '250px !important', lg: '400px !important' },
      }}
    />
  );
}

export default ImageSlide;
