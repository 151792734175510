import { Container, Box, Typography, Grid, useTheme } from '@mui/material';
import Image from 'mui-image';

function TestimonialWithPhoto(props) {
  // console.log('Component - TestimonialWithPhoto.js - props: ', props);

  const { testimonials } = props.props;
  const theme = useTheme();

  return (
    <Container
      maxWidth="xl"
      sx={{
        py: { xs: 2, md: 3 },
      }}
      className="MuiContainer--relatively-positioned"
    >
      {testimonials.map((testimonial) => {
        return (
          <Box
            key={testimonial.id}
            component="div"
            sx={{
              border: '1px solid black',
              borderRadius: '20px',
              my: { xs: 2, md: 20 },
              p: { md: 5 },
              fontFamily: theme.typography.primary,
            }}
          >
            <Typography
              sx={{
                position: 'relative',
                pt: 3,
                mb: { xs: 3, md: 5 },
                fontSize: 25,
                fontWeight: 'inherit',
                fontStyle: 'italic',
                '&::before': {
                  content: '" “"',
                  position: 'absolute',
                  left: 0,
                  fontSize: 250,
                  fontFamily: theme.typography.secondary,
                  lineHeight: 1,

                  [theme.breakpoints.up('md')]: {
                    bottom: '100%',
                    lineHeight: 0,
                    fontSize: 250,
                    transform: 'translate(-50%,-50%)',
                  },
                },
              }}
            >
              {testimonial.description}
            </Typography>
            <Grid
              container
              spacing={0}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent={{ xs: 'center' }}
              alignItems={{ xs: 'center' }}
            >
              {testimonial.photo.data && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    pr: { md: 2 },
                  }}
                >
                  <Image
                    src={
                      testimonial.photo.data.attributes.formats.thumbnail.url
                    }
                    alt={testimonial.photo.data.attributes.alternativeText}
                    showLoading
                    duration={500}
                    fit="cover"
                    width="auto"
                    loading="lazy"
                    sx={{
                      borderRadius: '50%',
                      maxWidth: '200px !important',
                      maxHeight: '200px !important',
                      objectPosition: 'center top',
                      float: 'left',
                    }}
                    wrapperStyle={{
                      float: 'left',
                    }}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  textAlign: 'center',
                  [theme.breakpoints.up('md')]: {
                    textAlign: testimonial.photo.data ? 'left' : 'center',
                  },
                }}
              >
                <Typography
                  component="h3"
                  sx={{
                    fontWeight: 700,
                    fontSize: '125%',
                  }}
                >
                  {testimonial.name}
                </Typography>
                <Typography>{testimonial.position}</Typography>
                <Typography>{testimonial.location}</Typography>
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </Container>
  );
}

export default TestimonialWithPhoto;
