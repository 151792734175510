import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@mui/material';

function ButtonWithOptions(props) {
  // console.log('Component - ButtonWithOptions.js - props: ', props);

  const { text, link, isExternal, isOutlined } = props.props;

  const buttonStyles = {
    px: 6,
    textAlign: 'center',
  };
  const buttonProps = {
    variant: isOutlined ? 'outlined' : 'contained',
    size: 'large',
  };

  return isExternal ? (
    <Button
      href={link}
      rel="noreferrer noopener"
      target="_blank"
      {...buttonProps}
      sx={buttonStyles}
    >
      {text}
    </Button>
  ) : (
    <Button to={link} component={RouterLink} {...buttonProps} sx={buttonStyles}>
      {text}
    </Button>
  );
}

export default ButtonWithOptions;
