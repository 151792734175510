import { Container, Box, Typography, Divider, alpha } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { keyframes } from '@mui/system';

function PageHeading(props) {
  // console.log('Component - PageHeading.js - props: ', props);

  const {
    props: { heading },
    name,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(useTheme().breakpoints.only('mobile'));
  const expandOuterX = keyframes`
    from {      
      width: 50%;
      opacity: 0;
    }
    50% {
      width: 0;
      opacity: 1;
    }
    to {
      width: 15%;
      opacity: 1,
    }
  `;
  const expandInnerX = keyframes`
    from {
      width: 0;
    }
    to {
      width: 30px;
    }
  `;

  const headingStyles = {
    animationOuterX: {
      animationName: `${expandOuterX}`,
      animationDuration: '3s',
      animationDelay: '.5s',
      animationIterationCount: '1',
      animationTimingFunction: 'ease',
      animationDirection: 'normal',
      animationFillMode: 'forwards',
    },
    animationInnerX: {
      animationName: `${expandInnerX}`,
      animationDuration: '1.5s',
      animationDelay: '2.5s',
      animationIterationCount: '1',
      animationTimingFunction: 'ease',
      animationDirection: 'normal',
      animationFillMode: 'forwards',
    },
  };

  return (
    <Container maxWidth="xl">
      <Box
        component="div"
        sx={{
          my: { xs: 2, md: 3 },
        }}
      >
        <Typography
          component="h1"
          variant="h3"
          sx={{
            my: 7,
            fontWeight: 200,
            color: theme.palette.custom.primary.main,
          }}
        >
          <Divider
            sx={{
              whiteSpace: isMobile ? 'normal' : 'pre',
              justifyContent: 'center',
              '::before,::after': {
                borderColor: alpha(theme.palette.custom.primary.main, 0.35),
                width: { xs: '0%' },
                ...headingStyles.animationOuterX,
              },
              '& .MuiDivider-wrapper': {
                position: 'relative',
                px: 2,
                '::before,::after': {
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  width: '0',
                  height: '2px',
                  transform: 'translateY(-50%)',
                  backgroundColor: theme.palette.custom.primary.main,
                  ...headingStyles.animationInnerX,
                },
                '::before': {
                  right: '100%',
                },
                '::after': {
                  left: '100%',
                },
              },
            }}
          >
            {heading ? heading : name}
          </Divider>
        </Typography>
      </Box>
    </Container>
  );
}

export default PageHeading;
