import { Container, Box, Typography, Modal } from '@mui/material';
import { Loading } from 'components/status/Status';

function PhotoModal(props) {
  // console.log('Component - PhotoModal.js - props: ', props);

  if (!props.props) return;

  const { url, alt, title, description } = props.props;

  return (
    <Container>
      <Modal
        open={props.open}
        onClick={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
          }}
        >
          {url && (
            <img
              src={url}
              alt={alt}
              title={title}
              style={{
                objectFit: 'contain',
                maxWidth: '90vw',
                maxHeight: '90vh',
              }}
            />
          )}
          {description && <Typography>{description}</Typography>}
          {!url && (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              className="Loader"
            >
              <Loading />
            </Box>
          )}
        </Box>
      </Modal>
    </Container>
  );
}

export default PhotoModal;
