import { Button, useTheme } from '@mui/material';

function ExternalButton(props) {
  // console.log('Component - ExternalButton.js - props: ', props);

  const theme = useTheme();

  if (props.props === null) return;

  const { text, link, isOutlined } = props.props;
  const { type } = props;
  const buttonStyles = {
    px: 6,
    textAlign: 'center',
  };
  const buttonProps = {
    variant: isOutlined ? 'outlined' : 'contained',
    size: 'large',
  };

  return (
    <Button
      href={link}
      rel="noreferrer noopener"
      target="_blank"
      disableElevation
      color="inherit"
      {...buttonProps}
      sx={{
        ...buttonStyles,
        ...(type === 'VideoGallery' && {
          position: 'absolute',
          bottom: '0',
          right: '0',
          backgroundColor: 'transparent',
          px: 1,
          letterSpacing: 5,
          color: theme.palette.grey[500],
          fontSize: 14,
          fontWeight: 600,
          '&:hover': {
            backgroundColor: theme.palette.grey[200],
          },
        }),
      }}
    >
      {text}
    </Button>
  );
}

export default ExternalButton;
