import { useState, useEffect, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Container,
  Box,
  Pagination,
  Stack,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Image from 'mui-image';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TESTIMONIALS } from 'queries/graphql';
import { Loading, NotFound } from 'components/status/Status';
gsap.registerPlugin(ScrollTrigger);

/*TODO 
refactor to use useQuery on render and useLazyQuery on pagination 
page change (event driven insted using useEffect, same refactor 
apply to other components that used only useLazyQuery)
*/
function TestimonialsWithPhoto(props) {
  // console.log('Component - TestimonialsWithPhoto.js - props: ', props);

  const theme = useTheme();
  const breakpointUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const [page, setPage] = useState(props.props.pagination.page);
  const pageCount = props.props.pagination.pageCount;
  const pageSize = props.props.pagination.pageSize;
  const total = props.props.pagination.total;

  const [isTestimonialsLoading, setIsTestimonialsLoading] = useState(true);
  const [testimonials, setTestimonials] = useState([]);

  const [getTestimonials, { called, loading, data }] = useLazyQuery(
    TESTIMONIALS,
    {
      onCompleted: (data) => {
        // console.log(
        //   'Component - TestimonialsWithPhoto.js - useLazyQuery - onCompleted: ',
        //   data.testimonials.data
        // );
        setTestimonials(data.testimonials.data);
        setIsTestimonialsLoading(false);
      },
      onError: (error) => {
        console.log(
          'Component - TestimonialsWithPhoto.js - useLazyQuery - onError: ',
          error
        );
      },
      fetchPolicy: 'network-only', // Doesn't check cache before making a network request
    }
  );

  useEffect(() => {
    getTestimonials({ variables: { page: page, pageSize: pageSize } });
  }, [page, pageSize, getTestimonials]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [page]);

  const handleChange = (value) => {
    setIsTestimonialsLoading(true);
    setPage(value);
  };

  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
    if (breakpointUpLg) {
      revealRefs.current.forEach((el) => {
        gsap.fromTo(
          el,
          {
            autoAlpha: 0.8,
            transform: 'scale(0.8)',
          },
          {
            autoAlpha: 1,
            transform: 'scale(1)',
            duration: 0.75,
            ease: 'power4.out',
            scrollTrigger: {
              trigger: el,
              start: 'top 70%',
              toggleActions: 'play none none reverse',
            },
            onComplete: function () {
              gsap.set(this.targets(), { clearProps: 'all' });
            },
          }
        );
      });
    }
  }, [breakpointUpLg, revealRefs, testimonials]);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  if (called && loading) {
    return <Loading />;
  }
  if (testimonials === null) {
    return <NotFound type="Testimonials" />;
  }

  return (
    <Container
      maxWidth="none"
      disableGutters
      className="MuiContainer--relatively-positioned MuiContainer--with-margin"
    >
      <Container
        maxWidth="xl"
        sx={{
          py: { xs: 2, md: 3 },
        }}
      >
        <Box sx={{ py: { xs: 1, md: 5 } }}>
          {testimonials.map((testimonial, index) => {
            return (
              <Box
                ref={addToRefs}
                key={testimonial.id}
                component="div"
                className={++index}
                sx={{
                  borderRadius: '20px',
                  my: { xs: 0, md: 20 },
                  mb: { xs: 6, md: 0 },
                  px: { xs: 0, md: 5 },
                  py: { xs: 3, md: 5 },
                  color: theme.palette.custom.primary.main,
                  fontFamily: theme.typography.primary,
                }}
              >
                <Typography
                  sx={{
                    position: 'relative',
                    mb: { xs: 3, md: 5 },
                    px: { xs: 2, md: 5 },
                    fontWeight: 'inherit',
                    fontStyle: 'italic',
                    fontSize: { xs: 16, lg: 25 },

                    '&::before': {
                      content: '" “"',
                      position: 'absolute',
                      color: theme.palette.custom.primary.main,
                      left: '50%',
                      bottom: '100%',
                      fontSize: 160,
                      fontFamily: theme.typography.secondary,
                      lineHeight: 0,
                      transform: 'translateX(-80%)',

                      [theme.breakpoints.up('md')]: {
                        left: 0,
                        bottom: '100%',
                        fontSize: 250,
                        transform: 'translate(-50%,-50%)',
                      },
                    },
                  }}
                >
                  {testimonial.attributes.description}
                </Typography>
                <Grid
                  container
                  spacing={0}
                  direction={{ xs: 'column', md: 'row' }}
                  justifyContent={{ xs: 'center' }}
                  alignItems={{ xs: 'center' }}
                  className="foo"
                >
                  {testimonial.attributes.photo.data && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        // border: '1px solid red',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        pr: { md: 2 },
                      }}
                    >
                      <Image
                        src={testimonial.attributes.photo.data.attributes.url}
                        alt={
                          testimonial.attributes.photo.data.attributes
                            .alternativeText
                        }
                        showLoading
                        duration={500}
                        fit="cover"
                        width="auto"
                        loading="lazy"
                        sx={{
                          borderRadius: '50%',
                          maxWidth: '200px !important',
                          maxHeight: '200px !important',
                          objectPosition: 'center top',
                          float: 'left',
                        }}
                        wrapperStyle={{
                          float: 'left',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      textAlign: 'center',
                      [theme.breakpoints.up('md')]: {
                        textAlign: testimonial.attributes.photo.data
                          ? 'left'
                          : 'center',
                      },
                    }}
                  >
                    <Typography
                      component="h3"
                      sx={{
                        fontWeight: 700,
                        fontSize: '125%',
                      }}
                    >
                      {testimonial.attributes.name}
                    </Typography>
                    <Typography>{testimonial.attributes.position}</Typography>
                    <Typography>{testimonial.attributes.location}</Typography>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </Box>
        {pageCount > 1 && (
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ py: { xs: 1, md: 3 } }}
          >
            <Pagination
              count={pageCount}
              page={page}
              onChange={handleChange}
              size="large"
              showFirstButton
              showLastButton
              siblingCount={1}
              boundaryCount={1}
              disabled={isTestimonialsLoading}
            />
            <Typography sx={{ color: 'white', fontSize: '75%' }}>
              {(page - 1) * pageSize + 1} - {page * pageSize} of {total}
            </Typography>
          </Stack>
        )}
      </Container>
    </Container>
  );
}

export default TestimonialsWithPhoto;
