import { Link as RouterLink } from 'react-router-dom';
import { List, ListItem, Link, Divider, Chip } from '@mui/material';

function ArticleCategories(props) {
  // console.log('Component - ArticleCategories.js - props: ', props);

  const categories = props.categories.data;
  const isSimple = props.type === 'simple' ? true : false;

  return (
    <List>
      {!isSimple && (
        <Divider textAlign="left" sx={{ mb: 5, textAlign: 'center' }}>
          <Chip label="CATEGORIES" />
        </Divider>
      )}
      {categories.map((category) => {
        return (
          <ListItem
            key={category.id}
            sx={{
              display: isSimple ? 'inline-flex' : 'flex',
              justifyContent: 'center',
              px: isSimple ? 0 : 2,
              mr: isSimple ? 1 : 0,
              pt: 0,
              fontSize: isSimple ? 12 : 16,
            }}
          >
            <Link
              to={`/category/${category.attributes.slug}`}
              component={RouterLink}
            >
              {category.attributes.name}
            </Link>
          </ListItem>
        );
      })}
    </List>
  );
}
export default ArticleCategories;
