import { useState } from 'react';
import {
  Container,
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import Markdown from 'markdown-to-jsx';

function ContactForm(props) {
  // console.log('Component - ContactForm.js - props: ', props);

  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const formStyles = {
    textField: {
      mb: 2,
    },
    formField: {
      minWidth: { xs: '100%', md: 400 },
    },
    formButton: {
      ...(success && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
      ...(error && {
        bgcolor: red[500],
        '&:hover': {
          bgcolor: red[700],
        },
      }),
    },
  };

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(false);
  const [emailStatus, setEmailStatus] = useState('');
  const [emailStatusText, setEmailStatusText] = useState('');
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);

  if (props.props.isShowForm === false) return;

  const resetInputFields = () => {
    setName('');
    setEmail('');
    setMessage('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setNameError(false);
    setEmailError(false);
    setMessageError(false);

    if (name === '') {
      setNameError(true);
    }
    if (email === '') {
      setEmailError(true);
    }
    if (message === '') {
      setMessageError(true);
    }

    if (name && email && message) {
      setIsEmailSubmitted(false);

      if (!loading) {
        setSuccess(true);
        setLoading(false);
      }

      fetch(`${process.env.REACT_APP_ENDPOINT}/api/email`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ name, email, message }),
      })
        .then((response) => {
          setIsEmailSubmitted(true);

          return response.json().then((data) => {
            setSuccess(true);
            setError(false);
            setLoading(false);
            const { success, error } = data;
            setEmailStatus(success ? 'success' : 'error');
            setEmailStatusText(success || error);
            resetInputFields();
            return data;
          });
        })
        .catch((error) => {
          console.log('Error: ', error);
          setError(true);
        });
    }
  };
  return (
    <Container
      sx={{
        py: { lg: 10 },
      }}
    >
      <Grid container spacing={{ xs: 0, md: 4 }} sx={{ py: { xs: 4, lg: 10 } }}>
        {(props.props.heading || props.props.description) && (
          <Grid item xs={12} md={6}>
            <Typography
              component="h3"
              variant="h4"
              sx={{ textTransform: 'uppercase' }}
            >
              {props.props.heading}
            </Typography>
            <Markdown>{props.props.description}</Markdown>
          </Grid>
        )}
        <Grid item xs={12} md={6} sx={{ margin: '0 auto' }}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <TextField
              onChange={(event) => setName(event.target.value)}
              value={name}
              sx={{ ...formStyles.textField }}
              label="Name"
              variant="filled"
              fullWidth
              required
              error={nameError}
            />
            <TextField
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              sx={{ ...formStyles.textField }}
              label="Email"
              variant="filled"
              fullWidth
              required
              error={emailError}
            />
            <TextField
              onChange={(event) => setMessage(event.target.value)}
              value={message}
              sx={{ ...formStyles.textField }}
              label="Message"
              variant="filled"
              multiline
              rows={4}
              fullWidth
              required
              error={messageError}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                my: { xs: 2, md: 4 },
              }}
            >
              <Box sx={{ m: 0, position: 'relative' }}>
                <Button
                  sx={formStyles.formButton}
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={loading}
                >
                  Submit
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
              {isEmailSubmitted && (
                <Box sx={{ mt: 2 }}>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      display: 'block',
                      color:
                        emailStatus === 'success'
                          ? theme.palette.success.main
                          : theme.palette.error.main,
                    }}
                  >
                    {emailStatusText}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ContactForm;
