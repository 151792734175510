import { Helmet, HelmetProvider } from 'react-helmet-async';

function Head(props) {
  const { title, description, blockSearchIndexing } = props.props;
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        {blockSearchIndexing && <meta name="robots" content="noindex" />}
      </Helmet>
    </HelmetProvider>
  );
}

export default Head;
