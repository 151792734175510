import { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  Typography,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';

function NewsletterForm() {
  const theme = useTheme();
  const breakpointUpMedium = useMediaQuery(useTheme().breakpoints.up('md'));
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailStatus, setEmailStatus] = useState('');
  const [emailStatusText, setEmailStatusText] = useState('');
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);

  const style = {
    textField: {
      mb: 1,
    },
    formField: {
      minWidth: { xs: '100%', md: 400 },
    },
    formButton: {
      ...(success && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
      ...(error && {
        bgcolor: red[500],
        '&:hover': {
          bgcolor: red[700],
        },
      }),
    },
  };

  const resetInputFields = () => {
    setEmail('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setEmailError(false);

    if (email === '') {
      setEmailError(true);
    }

    if (email) {
      resetInputFields();
      setIsEmailSubmitted(false);

      if (!loading) {
        setSuccess(true);
        setError(true);
        setLoading(false);
      }

      fetch(`${process.env.REACT_APP_ENDPOINT}/api/subscribe`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ email }),
      })
        .then((response) => {
          setIsEmailSubmitted(true);

          return response.json().then((data) => {
            const { success, error } = data;
            setEmailStatus(success ? 'success' : 'error');
            setEmailStatusText(success || error);

            if (success) {
              setSuccess(true);
              setError(false);
              setLoading(false);
            } else {
              setSuccess(false);
              setError(true);
              setLoading(false);
            }
            return data;
          });
        })
        .catch((error) => {
          console.log('Error: ', error);
        });
    }
  };
  return (
    <Container maxWidth="xl" disableGutters={breakpointUpMedium}>
      <Typography
        component="h3"
        variant="h6"
        align="center"
        textTransform="uppercase"
        sx={{ pt: 1, pb: 1, mb: 2, color: theme.palette.common.white }}
      >
        Subscribe to Mailing List
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
      >
        <Stack
          direction={{ xs: 'column' }}
          pb={{ xs: 4 }}
          spacing={2}
          sx={{ alignItems: 'center' }}
        >
          {isEmailSubmitted && (
            <Typography
              sx={{
                textAlign: 'left',
                color:
                  emailStatus === 'success'
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}
            >
              {emailStatusText}
            </Typography>
          )}
          <TextField
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            label="Email"
            variant="filled"
            size="small"
            required
            error={emailError}
            sx={{
              ...style.textField,
              ...style.formField,
            }}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            disabled={loading}
            sx={{
              ...style.formButton,
              ...style.formField,
            }}
          >
            Submit
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Stack>
      </Box>
    </Container>
  );
}

export default NewsletterForm;
