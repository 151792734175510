import { useRef, useEffect, useContext } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  alpha,
} from '@mui/material';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ButtonWithOptions from 'components/button/ButtonWithOptions';
import { PageContext } from 'pages/Homepage';
gsap.registerPlugin(ScrollTrigger);

function TextFieldsWithImage(props) {
  // console.log('Component - TextFieldsWithImage.js - props: ', props);

  const pageType = useContext(PageContext);
  const theme = useTheme();
  const breakpointUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const imageRef = useRef(null);
  const contentContainerRef = useRef(null);
  let isLightMode = null;

  useEffect(() => {
    if (breakpointUpLg && imageRef) {
      if (isLightMode) {
        const tlContent = gsap.timeline();

        tlContent.fromTo(
          contentContainerRef.current,
          {
            x: '3%',
          },
          {
            x: '0%',
            scrollTrigger: {
              trigger: contentContainerRef.current,
              start: 'top center',
              toggleActions: 'play complete none reset',
            },
          }
        );
      } else {
        const tlImage = gsap.timeline();
        tlImage.fromTo(
          imageRef.current,
          {
            scale: 0.975,
          },
          {
            scale: 1,
            scrollTrigger: {
              trigger: imageRef.current,
              start: 'top center',
              toggleActions: 'play complete none reset',
            },
          }
        );
      }
    }
  }, [breakpointUpLg, isLightMode]);

  if (props.props.textFieldsWithImage === null) return;

  const {
    heading,
    description,
    subDescription,
    isContentReversed,
    isDescriptionAQuote,
    isImageSkew,
    imageOverlayOpacity,
    image,
  } = props.props.textFieldsWithImage;

  isLightMode = props.props.textFieldsWithImage.isLightMode;

  return (
    <Container
      maxWidth="none"
      className={`MuiContainer--relatively-positioned text-fields-with-image  
      ${pageType === 'HomePage' && 'MuiContainer--dark-transparent-mode'}
      ${isLightMode ? 'MuiContainer--light-mode' : ''}`}
    >
      <Container
        maxWidth="xl"
        sx={{
          py: { xs: 2, md: 10 },
        }}
      >
        <Grid
          container
          direction={isContentReversed ? 'row-reverse' : 'row'}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 0, sm: 8, md: 12 }}
        >
          <Grid item xs={12} md={isLightMode ? 7 : 6}>
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
                transition: 'width .75s ease-out',
                transformOrigin: 'left center',
                transform: breakpointUpLg
                  ? isLightMode
                    ? 'none'
                    : isImageSkew
                    ? 'skew(-5deg, -5deg) rotate(5deg)'
                    : 'none'
                  : 'none',
                '::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: alpha(
                    theme.palette.custom.darkBlue.main,
                    imageOverlayOpacity
                  ),
                },
              }}
            >
              <Box
                component="div"
                sx={{
                  display: 'inline-flex',
                  width: '100%',
                  height: 'auto',
                  overflow: 'hidden',
                  transform: breakpointUpLg
                    ? isLightMode
                      ? 'none'
                      : isImageSkew
                      ? 'skew(5deg, 5deg) rotate(-5deg) scale(1.6)'
                      : 'none'
                    : 'none',
                  transformOrigin: 'center top',
                }}
              >
                <Box
                  ref={imageRef}
                  component="img"
                  src={image.data.attributes.url}
                  alt={image.data.attributes.alternativeText}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    transition: 'transform .75s ease-out',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={isLightMode ? 5 : 6}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Box
              ref={contentContainerRef}
              sx={{
                m: { md: 4 },
                color: theme.palette.custom.primary.main,
                transition: 'transform .75s ease-out, filter .5s linear',
              }}
            >
              {heading && (
                <Typography
                  component="h3"
                  variant="h4"
                  sx={{
                    textTransform: 'uppercase',
                    fontWeight: 500,
                    mb: { xs: 2, lg: 6 },
                  }}
                >
                  {heading}
                </Typography>
              )}
              <Typography
                sx={{
                  mb: { xs: 2, lg: 6 },
                  fontSize: { lg: 20 },
                  fontStyle: isDescriptionAQuote ? 'italic' : 'normal',
                  '&::before': {
                    content: isDescriptionAQuote ? 'open-quote' : null,
                  },
                  '&::after': {
                    content: isDescriptionAQuote ? 'close-quote' : null,
                  },
                }}
              >
                {description}
              </Typography>
              {subDescription && (
                <Typography
                  sx={{
                    mb: 6,
                    fontSize: 16,
                  }}
                >
                  {subDescription}
                </Typography>
              )}
              {props.props.button && (
                <ButtonWithOptions props={props.props.button} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

export default TextFieldsWithImage;
