import { useContext } from 'react';
import { Container, Box, Stack, useTheme, useMediaQuery } from '@mui/material';
import { keyframes } from '@mui/system';
import { PageContext } from 'pages/Homepage';

function FullwidthImage(props) {
  // console.log('Component - FullwidthImage.js - props:', props);

  const pageType = useContext(PageContext);

  const { isFullWidth, isFillFullWidth, isSetHeight } =
    props.props.fullwidthImage;

  const { url: mobileUrl, alternativeText: mobileAlternativeText } =
    props.props.fullwidthImage.imageViewportGroup.mobile.data.attributes;

  const { url: tabletUrl, alternativeText: tabletAlternativeText } = props.props
    .fullwidthImage.imageViewportGroup.tablet.data
    ? props.props.fullwidthImage.imageViewportGroup.tablet.data.attributes
    : props.props.fullwidthImage.imageViewportGroup.mobile.data.attributes;

  const { url: laptopUrl, alternativeText: laptopAlternativeText } =
    props.props.fullwidthImage.imageViewportGroup.laptop.data.attributes;

  const theme = useTheme();
  const breakpointUpMobile = useMediaQuery(theme.breakpoints.up('mobile'));
  const breakpointUpTablet = useMediaQuery(theme.breakpoints.up('tablet'));
  const breakpointUpLaptop = useMediaQuery(theme.breakpoints.up('laptop'));

  const imageSrc = () => {
    if (breakpointUpLaptop) return laptopUrl;
    if (breakpointUpTablet) return tabletUrl;
    if (breakpointUpMobile) return mobileUrl;
  };

  const imageAlt = () => {
    if (breakpointUpLaptop) return laptopAlternativeText;
    if (breakpointUpTablet) return tabletAlternativeText;
    if (breakpointUpMobile) return mobileAlternativeText;
  };

  const imageAnimation = keyframes`
    from {
      transform: scale(1.05) rotate(0deg);
      filter: grayscale(100%);
    }
    to {
      transform: scale(1) rotate(0deg);
      filter: grayscale(0%);
    }
  `;

  const imageAnimationStyles = {
    animationName: `${imageAnimation}`,
    animationDuration: '10s',
    animationDelay: '1s',
    animationIterationCount: '1',
    animationTimingFunction: 'ease',
    animationDirection: 'normal',
    animationFillMode: 'forwards',
    transform: 'scale(1.05)',
    transformOrigin: 'top center',
    filter: 'grayscale(100%)',
  };

  return (
    <Container maxWidth={isFullWidth ? 'none' : 'xl'} disableGutters>
      <Stack
        sx={{
          mb: pageType === 'HomePage' ? { xs: 0 } : { xs: 2, md: 10 },
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.custom.lightBlue.dark,
            lineHeight: 0,
            overflow: 'hidden',
          }}
        >
          <Box
            component="img"
            src={imageSrc()}
            alt={imageAlt()}
            sx={{
              objectFit: isFillFullWidth ? 'cover' : 'contain',
              objectPosition: 'center top',
              width: '100%',
              height: 'auto',
              [theme.breakpoints.up('lg')]: {
                ...imageAnimationStyles,
                height: isSetHeight ? '650px' : 'auto',
              },
            }}
          />
        </Box>
      </Stack>
    </Container>
  );
}

export default FullwidthImage;
