import { useQuery } from '@apollo/client';
import { Container, Typography, useTheme } from '@mui/material';
import { SITEWIDE } from 'queries/graphql.js';
import FooterNavigation from 'components/navigation/FooterNavigation';
import NewsLetterForm from 'components/form/NewsletterForm';
import WebsiteCopyright from 'components/website/WebsiteCopyright';
import WebsiteCredit from 'components/website/WebsiteCredit';
import ContactConnection from 'components/contact/ContactConnection';
import { Loading, Error } from 'components/status/Status';

function Footer() {
  const theme = useTheme();

  const { loading, error, data } = useQuery(SITEWIDE);
  // console.log('Component - Footer.js - useQuery - data: ', data);

  const footerStyles = {
    backgroundColor: {
      backgroundColor: theme.palette.custom.lightBlue.dark,
    },
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const {
    heading,
    copyright,
    isShowWebsiteCredit,
    isShowNewsletterForm,
    isShowFooterNavigation,
  } = data.sitewide.data.attributes.footer;

  return (
    <Container
      maxWidth="none"
      className="MuiContainer-footer MuiContainer--relatively-positioned"
      sx={{
        ...footerStyles.backgroundColor,
      }}
    >
      <Container
        component="footer"
        maxWidth="xl"
        sx={{
          py: 1,
        }}
      >
        {heading && (
          <Typography
            component="h3"
            variant="h5"
            sx={{
              textTransform: 'uppercase',
              textAlign: 'center',
              my: 2,
              color: theme.palette.common.white,
            }}
          >
            {heading}
          </Typography>
        )}
        <ContactConnection mode="dark" />
        {isShowNewsletterForm && <NewsLetterForm />}
        {isShowFooterNavigation && <FooterNavigation />}
        <WebsiteCopyright props={copyright} />
        {isShowWebsiteCredit && <WebsiteCredit />}
      </Container>
    </Container>
  );
}

export default Footer;
