import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { MenuList, MenuItem, Link } from '@mui/material';
import { NAVIGATION } from 'queries/graphql.js';
import { Loading, Error, NotFound } from 'components/status/Status';

function FooterNavigation() {
  const { loading, error, data } = useQuery(NAVIGATION);

  // console.log('Component - FooterNavigation.js - useQuery - data: ', data);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  if (data.navigation.data === null) {
    return <NotFound type="Footer Navigation" />;
  }

  const { links: menuItems } = data.navigation.data.attributes;

  return (
    <MenuList
      sx={{
        display: { xs: 'flex' },
        flexDirection: { md: 'row' },
        justifyContent: 'center',
        flexGrow: 1,
      }}
      dense
    >
      {menuItems.map((menuItem, index) => {
        if (menuItem.isShowInFooter === true) {
          return (
            <MenuItem
              key={menuItem.id + index}
              sx={{
                display: {
                  xs: 'flex',
                  alignItems: 'flex-start',
                  fontSize: '1rem',
                },
              }}
            >
              <Link
                to={menuItem.url}
                component={RouterLink}
                sx={{
                  pt: 1,
                  pb: 1,
                }}
              >
                {menuItem.label}
              </Link>
            </MenuItem>
          );
        }
        return null;
      })}
    </MenuList>
  );
}

export default FooterNavigation;
