import { useQuery } from '@apollo/client';
import { SITEWIDE } from 'queries/graphql.js';
import FullscreenNavigation from 'components/navigation/FullscreenNavigation';
import { Loading, Error } from 'components/status/Status';

function Header() {
  const { loading, error, data } = useQuery(SITEWIDE);
  // console.log('Component - Header.js - useQuery - data: ', data);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return <FullscreenNavigation />;
}

export default Header;
