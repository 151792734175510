import { useApolloClient, gql } from '@apollo/client';
import { Box, Typography, useTheme } from '@mui/material';

const CONTACT_ADDRESS = gql`
  query GetSitewide {
    sitewide {
      data {
        attributes {
          contactAddress {
            addressName
            addressLine
            addressCity
            addressState
            addressZipCode
            addressMapLink
            addressDescription
          }
        }
      }
    }
  }
`;

function ContactAddress() {
  const client = useApolloClient();
  const {
    sitewide: {
      data: {
        attributes: { contactAddress },
      },
    },
  } = client.readQuery({
    query: CONTACT_ADDRESS,
  });
  // console.log('Component - ContactAddress.js - readQuery - data: ', data);

  const theme = useTheme();

  const styles = {
    typography: {
      mb: 1.5,
      a: {
        color: theme.palette.custom.primary.main,
      },
      'a:hover': {
        textDecoration: 'none',
      },
    },
  };

  if (contactAddress === null) return;

  const {
    addressName,
    addressLine,
    addressCity,
    addressState,
    addressZipCode,
    addressMapLink,
    addressDescription,
  } = contactAddress;

  return (
    <Box sx={{ mb: 5 }}>
      <Typography sx={{ ...styles.typography }}>{addressName}</Typography>
      <Typography sx={{ ...styles.typography }}>{addressLine}</Typography>
      <Typography sx={{ ...styles.typography }}>
        {addressCity} {addressState} {addressZipCode}
        {addressMapLink && (
          <a
            href={addressMapLink}
            target="_blank"
            rel="noreferrer"
            style={{ marginLeft: '5px' }}
          >
            (map)
          </a>
        )}
      </Typography>

      {addressDescription && (
        <Typography sx={{ ...styles.typography }}>
          {addressDescription}
        </Typography>
      )}
    </Box>
  );
}

export default ContactAddress;
