import { useRef, useEffect, useContext } from 'react';
import {
  Container,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { PageContext } from 'pages/Homepage';
gsap.registerPlugin(ScrollTrigger);

function SimpleText(props) {
  // console.log('Component - SimpleText.js - props: ', props);

  const pageType = useContext(PageContext);
  const { description } = props.props.simpleText;
  const theme = useTheme();
  const breakpointUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const descriptionInnerRef = useRef(null);

  useEffect(() => {
    if (breakpointUpLg) {
      const tl = gsap.timeline();

      tl.fromTo(
        descriptionInnerRef.current,
        {
          x: '0%',
          y: '50%',
          filter: 'blur(5px)',
        },
        {
          x: '0%',
          y: '0%',
          filter: 'blur(0px)',
          scrollTrigger: {
            trigger: descriptionInnerRef.current,
            start: 'top bottom',
            toggleActions: 'play reset play reset',
          },
        }
      );
    }
  }, [breakpointUpLg]);

  return (
    <Container
      maxWidth="none"
      className={`MuiContainer--relatively-positioned MuiContainer--with-margin simple-text ${
        pageType === 'HomePage' && 'MuiContainer--dark-transparent-mode'
      } `}
    >
      <Container
        maxWidth="xl"
        sx={{
          py: { xs: 2, md: 20 },
        }}
      >
        <Box component="div">
          <Typography
            ref={descriptionInnerRef}
            component="div"
            className="simpe-text__description"
            sx={{
              fontFamily: theme.typography.primary,
              color: theme.palette.custom.primary.main,
              fontSize: { lg: 22 },
              transition: 'transform .5s ease-out, filter 1s ease-out',
            }}
          >
            {description}
          </Typography>
        </Box>
      </Container>
    </Container>
  );
}

export default SimpleText;
