import { useState, createContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { PAGE } from 'queries/graphql';
import { Loading, Error, NotFound } from 'components/status/Status';
import PageHeading from 'components/heading/PageHeading';
import RichTextWithImage from 'components/text/RichTextWithImage';
import RichText from 'components/text/RichText';
import ContactInformation from 'components/contact/ContactInformation';
import NestedAccordion from 'components/accordion/NestedAccordion';
import SimpleAccordion from 'components/accordion/SimpleAccordion';
import SimpleTestimonial from 'components/testimonial/SimpleTestimonial';
import RandomSimpleTestimonial from 'components/testimonial/RandomSimpleTestimonial';
import TestimonialWithPhoto from 'components/testimonial/TestimonialWithPhoto';
import ContactForm from 'components/form/ContactForm';
import ImageCarousel from 'components/carousel/ImageCarousel';
import FullwidthImage from 'components/image/FullwidthImage';
import Head from 'components/_base/head/Head';

export const PageContext = createContext(null);

function Page() {
  const [pageType] = useState('Page');
  const { slug } = useParams();
  const { loading, error, data } = useQuery(PAGE, {
    variables: {
      slug: slug,
    },
  });

  // console.log('Page - Page.js - useQuery - data: ', data);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  if (data.findSlug.data === null) {
    return <NotFound type="Page" />;
  }

  const { name, seo, pageHeading, components } = data.findSlug.data.attributes;

  return (
    <PageContext.Provider value={pageType}>
      {seo && <Head props={seo} slug={slug} />}
      {pageHeading && pageHeading.isShowHeadingPage && (
        <PageHeading props={pageHeading} name={name} />
      )}
      {components.map((component) => {
        switch (component.__typename) {
          case 'ComponentPageRandomSimpleTestimonial':
            return (
              component.randomSimpleTestimonial
                .isShowRandomSimpleTestimonial && (
                <RandomSimpleTestimonial
                  key={component.__typename + '_' + component.id}
                  props={component}
                />
              )
            );
          case 'ComponentPageSimpleTestimonial':
            return (
              <SimpleTestimonial
                key={component.__typename + '_' + component.id}
                props={component}
              />
            );
          case 'ComponentPageFullwidthImage':
            return (
              <FullwidthImage
                key={component.__typename + '_' + component.id}
                props={component}
              />
            );
          case 'ComponentPageImageCarousel':
            return (
              <ImageCarousel
                key={component.__typename + '_' + component.id}
                props={component}
              />
            );
          case 'ComponentPageContactForm':
            return (
              <ContactForm
                key={component.__typename + '_' + component.id}
                props={component}
              />
            );
          case 'ComponentPageTestimonialWithPhoto':
            return (
              <TestimonialWithPhoto
                key={component.__typename + '_' + component.id}
                props={component}
              />
            );
          case 'ComponentPageSimpleAccordion':
            return (
              <SimpleAccordion
                key={component.__typename + '_' + component.id}
                props={component}
              />
            );
          case 'ComponentPageNestedAccordion':
            return (
              <NestedAccordion
                key={component.__typename + '_' + component.id}
                props={component}
              />
            );
          case 'ComponentPageContactInformation':
            return (
              <ContactInformation
                key={component.__typename + '_' + component.id}
                props={component}
              />
            );
          case 'ComponentPageRichText':
            return (
              <RichText
                key={component.__typename + '_' + component.id}
                props={component}
              />
            );
          case 'ComponentPageRichTextWithImage':
            return (
              <RichTextWithImage
                key={component.__typename + '_' + component.id}
                props={component}
              />
            );
          default:
            return null;
        }
      })}
    </PageContext.Provider>
  );
}
export default Page;
