import { useContext } from 'react';
import { Container, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import TestimonialSlide from 'components/carousel/TestimonialSlide';
import { PageContext } from 'pages/Homepage';

SwiperCore.use([Autoplay, Pagination, Navigation]);

function TestimonialCarousel(props) {
  // console.log('Component - TestimonialCarousel.js - props: ', props);

  const pageType = useContext(PageContext);
  const theme = useTheme();
  const StyledSwiper = styled(Swiper)(({ theme }) => ({}));

  const {
    simpleTestimonialSlides,
    carouselProperties: {
      isAutoplay,
      isNavigation = true,
      isFullWidth = false,
      direction = 'horizontal',
    },
  } = props.props.testimonialCarousel;

  // console.log('isAutoplay: ', isAutoplay);

  const swiperStyles = {
    swiper: {
      border: '5px solid green !important',
      width: '100%',
      boxSizing: 'border-box',
    },
    slide: {
      boxSizing: 'border-box',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    link: {
      display: 'flex',
      justifyContent: 'center',
      p: 0,
      flexGrow: 1,
    },
  };

  const swiperProps = {
    direction: direction,
    pagination: {
      dynamicBullets: true,
      clickable: true,
    },
    navigation: isNavigation,
    loop: true,
    speed: 2000,
    autoplay: isAutoplay
      ? { delay: 5000, disableOnInteraction: false, pauseOnMouseEnter: true }
      : false,
  };

  return (
    <Container
      maxWidth="none"
      className={`MuiContainer--relatively-positioned MuiContainer--with-margin ${
        pageType === 'HomePage' && 'MuiContainer--dark-transparent-mode'
      } `}
    >
      <Container maxWidth={isFullWidth ? 'none' : 'xl'} disableGutters>
        <StyledSwiper
          {...swiperProps}
          modules={[Autoplay, Pagination, Navigation]}
          style={swiperStyles.swiper}
          sx={{
            '& .swiper-button-next, .swiper-button-prev': {
              color: theme.palette.custom.primary.main,
              [direction === 'vertical']: {
                left: '50%',
                transform: 'rotate(90deg)',
                transformOrigin: 'left center',
              },
            },
            '& .swiper-button-next': {
              [direction === 'vertical']: {},
            },
            '& .swiper-button-prev': {
              [direction === 'vertical']: {},
            },
            '& .swiper-pagination-bullet': {
              top: '10px',
            },
            '& .swiper-pagination-bullet-active': {
              background: theme.palette.custom.primary.main,
            },
          }}
        >
          {simpleTestimonialSlides.map((slide) => {
            return (
              <SwiperSlide key={slide.id} style={swiperStyles.slide}>
                <TestimonialSlide props={slide} />
              </SwiperSlide>
            );
          })}
        </StyledSwiper>
      </Container>
    </Container>
  );
}

export default TestimonialCarousel;
