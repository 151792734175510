import { Typography } from '@mui/material';

function WebsiteCopyright(props) {
  // console.log('Component - WebsiteCopyright.js - props:', props);

  const copyright = props.props;

  return (
    <Typography
      sx={{
        textAlign: 'center',
        fontSize: 14,
        color: 'common.white',
      }}
    >
      &copy; {new Date().getFullYear()} {copyright}
    </Typography>
  );
}

export default WebsiteCopyright;
